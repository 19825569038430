import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';
import Comments from '../../components/Comments';
import Gallery from '../../components/Gallery';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Photos() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { url } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [photos_items, setPhotosItems] = useState([]);

    async function loadPage(){
         setLoading(true);
        try{
            const response = await api.post(`/photos`, {}, config);
            setPhotos(response.data.data.photos);
            setPhotosItems([]);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadPhotos(){
         setLoading(true);
        try{
            const response = await api.post(`/photo/${url}`, {}, config);
            setPhotos(response.data.data.photos);
            setPhotosItems(response.data.data.photos_items);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function TagList({ tags }) {
        const tagArray = tags ? tags.split(',') : [];
        return (<>
            {tagArray.map((tag, index) => (
                <a key={index} href={`#tag-${tag}`} className="btn btn-sm btn-primary-light me-2">
                    {tag}
                </a>
            ))}</>
        );
    }

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
        if(url){
            loadPhotos();
            seHasContent(true);
        }else{
            loadPage();
            seHasContent(false)
        }
    }, [url]);

    function CardShimmer(){
        return (<div className="card custom-card bg-dark overlay-card text-fixed-white">
            <Shimmer source={base} rounded={0} />
            <div className="card-img-overlay d-flex flex-column p-0 over-content-bottom">
                <div className="card-footer border-top-0">
                    <h6 className="fw-semibold mb-0 text-fixed-white"><Shimmer width={200} /></h6>
                </div>
            </div>
        </div>);
    }

    function numbers() {
        return Math.floor(Math.random() * (600 - 60 + 1)) + 60;
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    {loading ?
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <h4><Shimmer width={240} height={40} /></h4>
                                        <div className="row gy-4">
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>:
                        <div className="row">
                            <div className="col-xl-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                        </div>}
                    </div>:
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-12" data-aos="fade-up">
                                <Link to="/photos" className="btn btn-primary label-btn mb-4" data-aos="fade-in">
                                    <div className="label-btn-icon fs-20 me-2">
                                        <RiArrowLeftSLine />
                                    </div>
                                    VOLTAR
                                </Link>
                                <div className="pb-0">
                                    <h4 className="fw-semibold">{photos.title}</h4>
                                    <div className="d-sm-flex align-items-cneter flex-column">
                                        <div className="d-flex align-items-center flex-fill">
                                            <p className="mb-2 fw-semibold">
                                                <span className="fs-11 text-muted fw-normal">{photos.date}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-12" data-aos="fade-up">
                                        <Gallery type="full" images={photos_items} />
                                    </div>
                                    <div className="col-xl-12 mt-4" data-aos="fade-up" data-aos-delay="50">
                                        <Comments type="posts" id={photos.id} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    <div className="col-xl-12" data-aos="fade-up">
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                   Autor
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Link to={`/profile/${photos.profile.username}`} className="d-flex align-items-center">
                                                        <div className="avatar avatar-rounded me-2">
                                                            <img src={base} className="image-cover" style={{backgroundImage: `url(${photos.profile.image})`}} />
                                                        </div>
                                                        <div className="flex-fill">
                                                            <div className="fs-15 fw-semibold">{photos.profile.name}</div>
                                                            <p className="mb-0 text-muted fs-11">@{photos.profile.username}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>:
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 className="text-black" data-aos="fade-up">Fotos</h4>
                            </div>
                            <div className="col-xl-12">
                                <div className="row gy-4">
                                    {photos.map((item, index) => (
                                    <div className="col-xl-4" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                        <Link to={`/photos/${item.url}`}>
                                            <div className="card custom-card overlay-card text-fixed-white card-hover">
                                                <img src={base} className="card-img image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                <div className="card-img-overlay d-flex flex-column p-0 over-content-bottom">
                                                    <div className="card-footer border-top-0">
                                                        <h6 className="fw-semibold mb-0 text-fixed-white">{item.title}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>))}
                                </div>
                            </div>
                        </div>}
                    </div>}
                </Content>
            </div>
        </div>
    );
}
