import React from 'react';
import ReactPlayer from 'react-player';

export default function Videos(props) {
    const { videos } = props;
    function videoUrl(id, type){
        if(type=="youtube"){
            return `https://www.youtube.com/watch?v=${id}`;
        }else if(type=="vimeo"){
            return `https://vimeo.com/${id}`;
        }else{
            return '';
        }
    }
    return (
        <>
            {videos.map((item, index) => (
            <div className="embed-container mb-4">
                <ReactPlayer
                    url={videoUrl(item.video, item.video_type)}
                    controls={true}
                    playsinline={true}
                    playing={false}
                    frameBorder="0"
                    allowFullScreen
                />
            </div>
            ))}
        </>
    );
}