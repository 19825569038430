import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';
import Podcast from '../../components/Podcast';
import ImageText from '../../components/ImageText';
import Videos from '../../components/Videos';
import Gallery from '../../components/Gallery';
import Comments from '../../components/Comments';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Events() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { url } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [posts, setPosts] = useState([]);
    const [posts_items, setPostsItems] = useState([]);
    const [other_posts, setOtherPosts] = useState([]);

    async function loadPage(){
         setLoading(true);
        try{
            const response = await api.post(`/events`, {}, config);
            setPosts(response.data.data.posts);
            setPostsItems([]);
            setOtherPosts([]);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadPosts(){
         setLoading(true);
        try{
            const response = await api.post(`/post/${url}/event`, {}, config);
            setPosts(response.data.data.posts);
            setPostsItems(response.data.data.posts.posts_items);
            setOtherPosts(response.data.data.other_posts);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function TagList({ tags }) {
        const tagArray = tags ? tags.split(',') : [];
        return (<>
            {tagArray.map((tag, index) => (
                <a key={index} href={`#tag-${tag}`} className="btn btn-sm btn-primary-light me-2">
                    {tag}
                </a>
            ))}</>
        );
    }

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
        if(url){
            loadPosts();
            seHasContent(true);
        }else{
            loadPage();
            seHasContent(false)
        }
    }, [url]);

    function CardShimmer(){
        return (<div className="card custom-card card-hover">
            <div className="row g-0">
                <div className="col-md-8">
                    <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="avatar avatar-rounded me-2">
                                <Shimmer source={base} rounded={100} />
                            </div>
                            <div className="flex-fill">
                                <div className="fs-15 fw-semibold"><Shimmer width={150} height={16} /></div>
                                <div className="mb-0 text-muted fs-11"><Shimmer width={100} height={12} /></div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="card-body pt-3">
                        <div className="fw-semibold fs-17 text-black mb-2"><Shimmer width={200} /></div>
                        <p className="mb-0"><Shimmer width={120} height={16} /></p>
                    </div>
                </div>
                <div className="col-md-4">
                    <Shimmer source={base} rounded={0} />
                </div>
            </div>
        </div>);
    }

    function numbers() {
        return Math.floor(Math.random() * (600 - 60 + 1)) + 60;
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    {loading ?
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                <h4><Shimmer width={240} height={40} /></h4>
                                                <hr className="border-secondary" />
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                                <div className="mb-2"><Shimmer width={numbers()} rounded={10} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>:
                        <div className="row">
                            <div className="col-xl-6">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                        </div>}
                    </div>:
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-12" data-aos="fade-up">
                                <Link to="/events" className="btn btn-primary label-btn mb-4" data-aos="fade-in">
                                    <div className="label-btn-icon fs-20 me-2">
                                        <RiArrowLeftSLine />
                                    </div>
                                    VOLTAR
                                </Link>
                            </div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-12" data-aos="fade-up">
                                        <div className="card custom-card" data-aos="fade-up" data-aos-delay="50">
                                            <div className="card-body pb-0">
                                                <h4 className="fw-semibold">{posts.title}</h4>
                                                <div className="d-sm-flex align-items-cneter flex-column">
                                                    <div className="d-flex align-items-center flex-fill">
                                                        <p className="mb-0 fw-semibold">
                                                            <span className="fs-11 text-muted fw-normal">{posts.date}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr className="mb-1" />
                                            </div>
                                            <div className="card-body post-content rounded border border-primary m-3 mb-1 pb-0" data-aos="fade-up">
                                                {posts.take &&
                                                <div className="fs-15">
                                                    <h5>O que levar?</h5>
                                                    <div dangerouslySetInnerHTML={{__html: posts.take}} />
                                                    <hr className="border-secondary" />
                                                </div>}
                                                {posts.workouts &&
                                                <div className="fs-15">
                                                    <h5>Workouts</h5>
                                                    <div dangerouslySetInnerHTML={{__html: posts.workouts}} />
                                                </div>}
                                            </div>
                                            
                                            {posts_items!='' &&
                                            <div className="card-body post-content">
                                                {posts_items.map((item, index) => (
                                                <div className="mb-3 fs-15" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                                    {item.type==="text" &&
                                                    <div dangerouslySetInnerHTML={{__html: item.text}} />}

                                                    {item.type==="podcast" &&
                                                    <Podcast type={item.podcast_type} id={item.podcast} />}
                                                
                                                    {item.type==="imagetext" &&
                                                    <ImageText type={item.image_type} image={item.image} text={item.text} />}

                                                    {item.type==="video" &&
                                                    <Videos videos={item.videos} />}
                                                    
                                                    {item.type==="image" &&
                                                    <Gallery images={item.gallery} />}

                                                    {item.type==="button" &&
                                                    <a href={item.link} target="_blank" className="btn btn-custom" style={{color: item.link_color, backgroundColor: item.link_bg}}>
                                                        {item.link_text}
                                                    </a>}

                                                </div>))}
                                            </div>}
                                            {posts.tags &&
                                            <>
                                                <div className="card-header pt-0" data-aos="fade-down" data-aos-delay="50">
                                                    <div className="card-title">
                                                        Tags
                                                    </div>
                                                </div>
                                                <div className="card-body" data-aos="fade-down">
                                                    <div className="blog-popular-tags">
                                                        <TagList tags={posts.tags} />
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                    <div className="col-xl-12" data-aos="fade-up" data-aos-delay="50">
                                        <Comments type="posts" id={posts.id} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    <div className="col-xl-12" data-aos="fade-up">
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                   Autor
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Link to={`/profile/${posts.profile.username}`} className="d-flex align-items-center">
                                                        <div className="avatar avatar-rounded me-2">
                                                            <img src={base} className="image-cover" style={{backgroundImage: `url(${posts.profile.image})`}} />
                                                        </div>
                                                        <div className="flex-fill">
                                                            <div className="fs-15 fw-semibold">{posts.profile.name}</div>
                                                            <p className="mb-0 text-muted fs-11">@{posts.profile.username}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12" data-aos="fade-up" data-aos-delay="50">
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    Sobre o Evento
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <ul className="list-group list-unstyled">
                                                    <li className="d-flex justify-content-between align-items-start mb-4">
                                                        <div className="text-muted">
                                                            <div className="fw-semibold fs-16 text-black">Início do Evento:</div>
                                                            {posts.started}
                                                        </div>
                                                    </li>
                                                    <li className="d-flex justify-content-between align-items-start mb-4">
                                                        <div className="text-muted">
                                                            <div className="fw-semibold fs-16 text-black">Final do Evento:</div>
                                                            {posts.ended}
                                                        </div>
                                                    </li>
                                                    <li className="d-flex justify-content-between align-items-start">
                                                        <div className="text-muted">
                                                            <div className="fw-semibold fs-16 text-black">Pagamento:</div>
                                                            {posts.type=='free' ?
                                                            <div className="fw-semibold fs-16 text-success">Gratuito</div>:
                                                            <div className="fw-semibold fs-16 text-success">{posts.price}</div>}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12" data-aos="fade-up" data-aos-delay="100">
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    Outros Eventos
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <ul className="list-group list-custom">
                                                    {other_posts.map((item, index) => (
                                                    <li className="list-group-item" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                                        <Link to={`/events/${item.url}`}>
                                                            <div className="d-flex gap-3 flex-wrap align-items-center">
                                                                <span className="avatar avatar-xl">
                                                                    <img src={base} className="img-fluid image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                </span>
                                                                <div className="flex-fill">
                                                                    <h4 className="fs-15 fw-semibold mb-0 op-1-1">{item.title}</h4>
                                                                    <p className="mb-1 popular-blog-content text-truncate">{item.subtitle}</p>
                                                                    <span className="text-muted fs-11">{item.date}</span>
                                                                </div>
                                                                <div className="list-arrow">
                                                                    <RiArrowRightSLine />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>:
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 className="text-black" data-aos="fade-up">Eventos</h4>
                            </div>
                            <div className="col-xl-12">
                                <div className="row gy-4">
                                    {posts.map((item, index) => (
                                    <div className="col-xl-6" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                        <div className="card custom-card card-hover">
                                            <Link to={`/events/${item.url}`}>
                                                <div className="row g-0">
                                                    <div className="col-md-8">
                                                        <div className="card-header">
                                                            <div className="d-flex align-items-center w-100">
                                                                <Link to={`/profile/${item.profile.username}`} className={`d-flex align-items-center ${item.profile.verified ? 'verified' : ''}`}>
                                                                    <div className="avatar avatar-rounded me-2">
                                                                        <img src={base} className="image-cover" style={{backgroundImage: `url(${item.profile.image})`}} />
                                                                    </div>
                                                                    <div className="flex-fill">
                                                                        <div className="fs-15 fw-semibold">{item.profile.name}</div>
                                                                        <p className="mb-0 text-muted fs-11">@{item.profile.username} - {item.date}</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-3">
                                                            <div className="fw-semibold fs-17 text-black mb-2">{item.title}</div>
                                                            <p className="mb-0">{item.subtitle}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <img src={base} className="img-fluid rounded-end h-100 image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                        </div>}
                    </div>}
                </Content>
            </div>
        </div>
    );
}
