import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import logo from '../../assets/images/logo.png';
import appstore from '../../assets/images/btn_appstore.png';
import playstore from '../../assets/images/btn_playstore.png';

export default function Hotel({ id }) {

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [loading, setLoading] = useState(true);
    const [loading_page, setLoadingPage] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [logo_hotel, setLogo] = useState('');

    async function submitPayment(e){
        e.preventDefault();
        setLoadingPage(true);
        if(name=="" ||
            last_name=="" ||
            email==""
        ){
            setLoadingPage(false);
            setError(true);
            setErrorMessage("Preencha todos os campos!");
            return;
        }

        try{
            const response = await api.post(`/register-hotel`, {
                id,
                name,
                last_name,
                email,
            });

            if(response.data.status=="error"){
                setLoading(false);
                setError(true);
                setErrorMessage(response.data.message);
                setLoadingPage(false);
                return;
            }
            
            setSuccess(true);
            setLoading(false);
            setError(false);
            setErrorMessage('');
            setLoadingPage(false);

            setPassword(response.data.password);
        }catch(error){
            setLoading(false);
            setError(true);
            setErrorMessage(error.message);
            setLoadingPage(false);
        }
    }

    async function loadPage(){
        try{
            const response = await api.post(`/hotel`, { id });
            setLogo(response.data.data.logo);
            setLoading(false);
            setLoadingPage(false);
        }catch(err){
            setLoading(false);
            setLoadingPage(false);
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="landing-page-wrapper">
            {loading_page &&
            <div id="loader">
                <div className="loader-content"><div className="sbl-circ-path"></div></div>
            </div>}
            <header className="app-payment bg-light">
                <div className="container p-0">
                    <div className="landing-logo-container">
                        <div className="horizontal-logo">
                            <div className="header-logo d-flex align-items-center justify-content-center gap-5">
                                <img src={logo} className="logo" />
                                {logo_hotel && <img src={logo_hotel} className="logo" />}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main-content landing-main">
                {!loading &&
                <section className="section hotel-title">
                    <div className="container">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-xl-6">
                                <h2 className="fw-semibold mb-2">Cadastro</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-3">
                            <div className="col-lg-6">
                                <div className="card-form">
                                    {success ?
                                    <div className="card bg-white border-0">
                                        <div className="alert custom-alert alert-primary">
                                            <div className="text-center pb-0">
                                                <svg className="custom-alert-icon svg-primary" xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 24 24" width="1.5rem" fill="#000000"><path d="M0 0h24v24H0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>
                                                <h5>Cadastro realizado com sucesso!</h5>
                                                <span className="text-black fs-16 fw-bold">Você receberá um e-mail com as instruções de login para o App BRUK Fit.</span>
                                                <div className="mt-5 mb-2 text-dark fs-20 fw-bold">Seu acesso:</div>
                                                <div className="mb-1 text-dark fs-16 fw-bold"><strong className="text-primary">E-mail:</strong> {email}</div>
                                                <div className="text-dark fs-16 fw-bold"><strong className="text-primary">Senha:</strong> {password}</div>

                                            </div>
                                        </div>
                                        <h5 className="mt-5 mb-4 text-dark text-center">Baixe o App BRUK Fit:</h5>
                                        <div className="d-flex align-items-center justify-content-center gap-4">
                                            <a href="https://apps.apple.com/br/app/bruk/id6478118912" target="_blank">
                                                <img src={appstore} />
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.etc.bruk" target="_blank">
                                                <img src={playstore} />
                                            </a>
                                        </div>
                                    </div>:
                                    <div className="card-form__inner">
                                        <div className="text-left mb-4">
                                            <span className="text-light fs-16 fw-bold">Coloque seus dados para ter acesso Exclusivo ao App BRUK Fit</span>
                                        </div>
                                        
                                        <div className="card-input">
                                            <input type="text" placeholder="Nome" className="card-input__input" value={name} onChange={e => setName(e.target.value)} />
                                        </div>
                                        <div className="card-input">
                                            <input type="text" placeholder="Sobrenome" className="card-input__input" value={last_name} onChange={e => setLastName(e.target.value)} />
                                        </div>
                                        <div className="card-input">
                                            <input type="email" placeholder="E-mail" className="card-input__input" value={email} onChange={e => setEmail(e.target.value)} />
                                        </div>
                                        <div className="d-grid">
                                            {error &&
                                            <div className="alert alert-dismissible alert-danger mt-3">{error_message}</div>}
                                            <button className="btn btn-primary btn-lg btn-wave waves-effect waves-light mt-3" onClick={submitPayment}>CADASTRAR</button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </section>}
            </div>

        </div>
    );
}