import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaHeart, FaRegHeart } from "react-icons/fa6";

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import AddPost from '../../components/AddPost';
import Comments from '../../components/Comments';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';
import base_video from '../../assets/images/blank-video.png';

export default function Home() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [feed, setFeed] = useState([]);
    const [branch, setBranch] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post(`/feed`, {}, config);
            setFeed(response.data.data.feed);
            setBranch(response.data.data.branch);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function handleLike(type, id_content){
        let type_content = '';
        if(type==='blog' || type==='event' || type==='social' || type==='photo'){
            type_content = 'post';
        }else if(type=='normal' || type==='course' || type==='stream' || type==='program'){
            type_content = 'product';
        }
        try{
            const response = await api.post(`/like/${type_content}/${id_content}`, {}, config);
            setFeed(prevPosts =>
                prevPosts.map(post => post.id === id_content ? { ...post, like: response.data.liked } : post)
            );
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
        loadPage();
    }, []);

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">

                                    <div className="col-xl-12" data-aos="fade-up">
                                        <AddPost />
                                    </div>
                                    {feed.map((item, index) => (
                                    <div className="col-xl-12" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="d-flex align-items-center w-100">
                                                    <Link to={`/profile/${item.profile.username}`} className={`d-flex align-items-center ${item.profile.verified ? 'verified' : ''}`}>
                                                        <div className="avatar avatar-rounded me-2">
                                                            <img src={base} className="image-cover" style={{backgroundImage: `url(${item.profile.image})`}} />
                                                        </div>
                                                        <div className="flex-fill">
                                                            <div className="fs-15 fw-semibold">{item.profile.name}</div>
                                                            <p className="mb-0 text-muted fs-11">@{item.profile.username} - {item.date}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        {item.type==='stream' &&<h5 className="text-black">Streaming</h5>}
                                                        {item.type==='event' &&<h5 className="text-black">Evento</h5>}
                                                        {item.type==='blog' &&<h5 className="text-black">Novidade</h5>}
                                                        {item.type==='photo' &&<h5 className="text-black">Novo Álbum</h5>}
                                                        {item.type==='course' &&<h5 className="text-black">Curso</h5>}
                                                    </div>
                                                    <div className="col-xl-12">
                                                        {item.type==='stream' &&
                                                        <Link to={`/streaming/${item.url}`}>
                                                            <div className="card custom-card overlay-card text-fixed-white card-hover-opacity">
                                                                {item.live &&<div className="card-live">🔴 AO VIVO</div>}
                                                                <img src={base_video} className="card-img image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                <div className="card-img-overlay d-flex flex-column p-0 over-content-bottom">
                                                                    <div className="card-footer border-top-0">
                                                                        <h6 className="fw-semibold mb-0 text-fixed-white">{item.title}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>}
                                                        {item.type==='event' &&
                                                        <div className="card custom-card card-hover-opacity border">
                                                            <Link to={`/events/${item.url}`}>
                                                                <div className="row g-0">
                                                                    <div className="col-md-8">
                                                                        <div className="card-body pt-3">
                                                                            <div className="fw-semibold fs-17 text-black mb-2">{item.title}</div>
                                                                            <p className="mb-0">{item.subtitle}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <img src={base} className="img-fluid rounded-end h-100 image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>}
                                                        {item.type==='blog' &&
                                                        <div className="card custom-card card-hover-opacity border">
                                                            <Link to={`/news/${item.url}`}>
                                                                <img src={base} className="card-img-top image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                <div className="card-body pt-3">
                                                                    <div className="fw-semibold fs-17 text-black mb-2">{item.title}</div>
                                                                    <p className="mb-0 line-one">{item.subtitle}</p>
                                                                </div>
                                                            </Link>
                                                        </div>}
                                                        {item.type==='photo' &&
                                                        <Link to={`/photos/${item.url}`}>
                                                            <div className="card custom-card overlay-card text-fixed-white card-hover-opacity">
                                                                <img src={base} className="card-img image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                <div className="card-img-overlay d-flex flex-column p-0 over-content-bottom">
                                                                    <div className="card-footer border-top-0">
                                                                        <h6 className="fw-semibold mb-0 text-fixed-white">{item.title}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>}
                                                        {item.type==='course' &&
                                                        <Link to={`/courses/${item.url}`}>
                                                            <div className="card custom-card overflow-hidden card-hover-opacity border">
                                                                <div className="ecommerce-sale-image">
                                                                    <img src={base} className="card-img-top image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                    <div className="card-img-overlay d-flex flex-column p-4 text-fixed-white">
                                                                        <p className="ecommerce-sales-calendar text-center mb-0">
                                                                            <span className="d-block fs-15 lh-1">
                                                                                <span className="fw-semibold">{item.total}</span> Aulas
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="text-black fs-17 fw-semibold">{item.title}</div>
                                                                    <p className="mb-3">{item.description}</p>
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="btn btn-primary btn-wave me-2 waves-effect waves-light">Acessar Curso</div>
                                                                        <div className="fw-semibold text-success">R$ {item.price}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>}
                                                        {item.type==='social' &&
                                                        <div className="mb-3 fs-15 post-content">
                                                            <div dangerouslySetInnerHTML={{__html: item.description}} />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    {item.like ?
                                                    <div className="fs-semibold fs-20 text-danger link-like" onClick={() => handleLike(item.type, item.id)}>
                                                        <FaHeart />
                                                    </div>:
                                                    <div className="fs-semibold fs-20 link-like" onClick={() => handleLike(item.type, item.id)}>
                                                        <FaRegHeart />
                                                    </div>}
                                                    {item.type==='stream' &&
                                                    <Link to={`/streaming/${item.url}`}>
                                                        <div className="fw-semibold">{item.total_comments} Comentários</div>
                                                    </Link>}
                                                    {item.type==='event' &&
                                                    <Link to={`/events/${item.url}`}>
                                                        <div className="fw-semibold">{item.total_comments} Comentários</div>
                                                    </Link>}
                                                    {item.type==='blog' &&
                                                    <Link to={`/news/${item.url}`}>
                                                        <div className="fw-semibold">{item.total_comments} Comentários</div>
                                                    </Link>}
                                                    {item.type==='photo' &&
                                                    <Link to={`/photos/${item.url}`}>
                                                        <div className="fw-semibold">{item.total_comments} Comentários</div>
                                                    </Link>}
                                                    {item.type==='course' &&
                                                    <Link to={`/courses/${item.url}`}>
                                                        <div className="fw-semibold">{item.total_comments} Comentários</div>
                                                    </Link>}
                                                    {item.type==='social' &&
                                                    <div className="fw-semibold">{item.total_comments} Comentários</div>}
                                                </div>
                                                {item.type==='social' &&
                                                <Comments type="posts" id={item.id} title={false} />}
                                            </div>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    <div className="col-xl-12" data-aos="fade-up">
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                Unidades BRUK
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {branch.map((item, index) => (
                                                <div className="d-flex align-items-center branches" key={index} data-aos="fade-up" data-aos-delay={`${(index + 1) * 100}`}>
                                                    <Link to={`/profile/${item.username}`} className={`d-flex align-items-center ${item.verified ? 'verified' : ''}`}>
                                                        <div className="avatar avatar-rounded avatar-xl me-2">
                                                            <img src={base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                        </div>
                                                        <div className="flex-fill">
                                                            <div className="fs-15 fw-semibold">{item.name}</div>
                                                            <p className="mb-0 text-muted fs-11">@{item.username}</p>
                                                        </div>
                                                    </Link>
                                                </div>))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
}
