import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiCheckDoubleFill, RiSendPlaneFill } from "react-icons/ri";

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Messages() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { id } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [user_message, setUserMessage] = useState([]);
    const [message, setMessage] = useState('');

    async function loadPage(){
        try{
            const response = await api.post(`/messages`, {}, config);
            setUsers(response.data.data.users);
            setLoading(false);
        }catch(err){
            console.log(err.message);
            setLoading(false);
        }
    }

    async function openMessage(){
        try{
            const response = await api.post(`/message/${id}`, {}, config);
            setMessages(response.data.data.messages);
            setUserMessage(response.data.data.user);
            setLoading(false);
        }catch(err){
            console.log(err.message);
            setLoading(false);
        }
    }

    async function sendMessage(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(message==""){
                setLoading(false);
                return;
            }
            await api.post(`/send-message/${id}`, {message: message.trim()}, config);
            setMessage('');
            openMessage();
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
        loadPage();
    }, []);

    useEffect(() => {
        if(id){
            openMessage();
            seHasContent(true);
        }else{
            seHasContent(false)
        }
    }, [id]);

    function MessagesShimmer(){
        return (
            <li>
                <a style={{pointerEvents: 'none'}}>
                    <div className="d-flex align-items-top">
                        <div className="me-1 lh-1">
                            <span className="avatar avatar-md me-2 avatar-rounded">
                                <Shimmer source={base} rounded={100} />
                            </span>
                        </div>
                        <div className="flex-fill">
                            <p className="mb-0 fw-semibold">
                                <Shimmer width={100} height={16} />
                            </p>
                            <p className="fs-12 mb-0">
                                <Shimmer width={50} height={14} />
                            </p>
                        </div>
                    </div>
                </a>
            </li>
        )
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    <div className="main-chart-wrapper gap-2 d-lg-flex">
                        <div className="chat-info border">
                            <ul className="list-unstyled mb-0 mt-0 chat-users-tab" id="chat-msg-scroll">
                                {users.map(item => (
                                <li key={item.id} className={`fs-12 mb-0 ${item.unread>=1 ? 'chat-msg-unread' : item.id===id ? 'chat-msg-active' : ''}`}>
                                    <Link to={`/messages/${item.id}`}>
                                        <div className="d-flex align-items-top">
                                            <div className="me-1 lh-1">
                                                <span className="avatar avatar-md me-2 avatar-rounded">
                                                    <img src={base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                </span>
                                            </div>
                                            <div className={`flex-fill ${item.verified ? 'verified-m' : ''}`}>
                                                <p className="mb-0 fw-semibold">{item.name} <span className="float-end text-muted fw-normal fs-11">{item.date}</span></p>
                                                <p className={`fs-12 mb-0 ${item.unread>=1 ? 'chat-msg-typing' : ''}`}>
                                                    <span className="chat-msg text-truncate">{item.message}</span>
                                                    <span className="chat-read-icon float-end align-middle"><RiCheckDoubleFill /></span>
                                                    {item.unread>=1 && <span className="badge bg-success-transparent rounded-circle float-end">{item.unread}</span>}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>))}
                                
                                {loading &&
                                <>
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                    <MessagesShimmer />
                                </>}
                                
                            </ul>
                        </div>

                        {has_content ?
                        <div className="main-chat-area border">
                            <div className="d-flex align-items-center p-2 border-bottom">
                                <Link to={`/profile/${user_message.username}`} className="d-flex align-items-center">
                                    <div className="me-1 lh-1">
                                        <span className="avatar avatar-lg me-1 avatar-rounded chatstatusperson">
                                            <img src={base} className="chatimageperson image-cover" style={{backgroundImage: `url(${user_message.image})`}} />
                                        </span>
                                    </div>
                                    <div className={`flex-fill ${user_message.verified ? 'verified' : ''}`}>
                                        <p className="mb-0 fw-semibold fs-14">
                                            <span className="chatnameperson responsive-userinfo-open">{user_message.name}</span>
                                        </p>
                                        <p className="text-muted mb-0 chatpersonstatus">@{user_message.username}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="chat-content" id="main-chat-content">
                                <ul className="list-unstyled">
                                    {messages.map(item => (
                                    <li className={`mb-0 ${item.id===id ? 'chat-item-start' : 'chat-item-end'}`}>
                                        <div className="chat-list-inner">
                                            {item.id===id ?
                                            <>
                                                <div className="ms-3 d-flex align-items-start flex-column">
                                                    <div className="main-chat-msg">
                                                        <div>
                                                            <p className="mb-0">{item.text}</p>
                                                        </div>
                                                    </div>
                                                    <span className="chatting-user-info">
                                                        <span className="msg-sent-time">
                                                            <span className="fs-10">{item.time}</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </>:
                                            <>
                                                <div className="me-3  d-flex align-items-end flex-column">
                                                    <div className="main-chat-msg">
                                                        <div>
                                                            <p className="mb-0">{item.text}</p>
                                                        </div>
                                                    </div>
                                                    <span className="chatting-user-info">
                                                        <span className="msg-sent-time">
                                                            <span className="fs-10">{item.time}</span>
                                                            <span className={`fs-15 ms-1 chat-mark align-middle d-inline-flex ${item.status==='yes' ? 'chat-read-mark' : ''}`}>
                                                                <RiCheckDoubleFill />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </>}
                                        </div>
                                    </li>))}
                                </ul>
                            </div>
                            <form onSubmit={sendMessage} className="chat-footer">
                                <input type="text" className="form-control" placeholder="Escreva sua mensagem..." value={message} onChange={e => setMessage(e.target.value)} />
                                <button type="submit" className="btn btn-primary btn-icon btn-send mx-2">
                                    <RiSendPlaneFill />
                                </button>
                            </form>
                        </div>:
                        <div className="main-chat-area border d-flex align-items-center justify-content-center">
                            <p className="fs-16 mb-0">Selecione uma conversa ao lado para começar!</p>
                        </div>}
                    </div>
                </Content>
            </div>
        </div>
    );
}
