import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import brLocale from '@fullcalendar/core/locales/pt-br';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Programs() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { id } = useParams();
    const today = new Date().toISOString().replace(/T.*$/, '')

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [events, setEvents] = useState([]);

    async function loadPage(){
        seHasContent(false);
        try{
            const response = await api.post(`/programs`, {}, config);
            setPrograms(response.data.data.program);
            setEvents([])
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadProgram(){
        seHasContent(true);
        try{
            const response = await api.post(`/programs/${id}`, {}, config);
            setPrograms(response.data.data.program);
            setEvents(response.data.data.events);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if(!signed){
            navigate('/login?redirect=/programs');
            return;
        }
        if(id){
            loadProgram();
        }else{
            loadPage();
        }
    }, [id]);

    function handleEventClick(clickInfo) {
        navigate(`/program/${clickInfo.event.id}`);
    }

    function CardShimmer(){
        return (<div className="card custom-card">
            <div className="card-img-top">
                <Shimmer source={base} rounded={0} />
            </div>
            <div className="card-body pt-3">
                <div className="fw-semibold fs-17 text-black mb-2"><Shimmer width={200} /></div>
                <Shimmer height={16} />
                <Shimmer width={120} height={16} />
            </div>

            <div className="card-footer">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="avatar avatar-rounded me-2">
                            <Shimmer source={base} rounded={100} />
                        </div>
                        <div className="flex-fill">
                            <div className="fs-15 fw-semibold"><Shimmer width={150} height={16} /></div>
                            <div className="mb-0 text-muted fs-11"><Shimmer width={100} height={12} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    {loading ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                {has_content ?
                                <div className="row">
                                    <div className="col-xl-12">
                                        <h4><Shimmer width={240} height={40} /></h4>
                                        <hr className="border-secondary" />
                                    </div>
                                    <div className="col-xl-12">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mt-4">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mt-4">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mt-4">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                </div>:
                                <div className="row">
                                    <div className="col-xl-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4 mt-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4 mt-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4 mt-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4 mt-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4 mt-4">
                                        <CardShimmer />
                                    </div>
                                    <div className="col-xl-4 mt-4">
                                        <CardShimmer />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>:
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-12 mb-4">
                                <Link to="/programs" className="btn btn-primary label-btn" data-aos="fade-up">
                                    <div className="label-btn-icon fs-20 me-2">
                                        <RiArrowLeftSLine />
                                    </div>
                                    VOLTAR
                                </Link>
                            </div>
                            {programs.buy=='yes' ?
                            <>
                                <div className="col-xl-12">
                                    <h4 className="text-black" data-aos="fade-up" data-aos-delay="50">{programs.title}</h4>
                                    <div className="fs-15" data-aos="fade-up" data-aos-delay="100" dangerouslySetInnerHTML={{__html: programs.text}} />
                                    <div className="fs-15" data-aos="fade-up" data-aos-delay="150" dangerouslySetInnerHTML={{__html: `<strong class="fs-16">Observações:</strong> ${programs.observations}`}} />
                                    <hr data-aos="fade-up" data-aos-delay="150" className="border-secondary" />
                                </div>
                                <div className="col-xl-12 mt-4" data-aos="fade-up" data-aos-delay="200">
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                        initialView='dayGridMonth'
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                        }}
                                        locale={brLocale}
                                        initialDate={today}
                                        navLinks={true}
                                        droppable={true}
                                        dayMaxEvents={true}
                                        expandRows={true}
                                        eventTimeFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }}
                                        eventClick={handleEventClick}
                                        events={events}
                                    />
                                </div>
                            </>:
                            <>
                                <div className="col-xl-12">
                                    <h4 className="text-black" data-aos="fade-up" data-aos-delay="50">{programs.title}</h4>
                                    <p className="fs-15" data-aos="fade-up" data-aos-delay="100">Você ainda não tem acesso a esse Programa</p>
                                    <a href="https://planilhas.bruk.digital/14-dias-gratis" target="_blank" className="btn btn-primary" data-aos="fade-up" data-aos-delay="150">
                                        Escolher um Plano
                                    </a>
                                </div>
                            </>}
                        </div>:
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 className="text-black" data-aos="fade-up">Programas</h4>
                            </div>
                            <div className="col-xl-12">
                                <div className="row">
                                    {programs.map((item, index) => (
                                    <div className="col-xl-4" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                        <div className="card custom-card card-hover">
                                            <Link to={`/programs/${item.id}`}>
                                                <img src={base} className="card-img-top image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                <div className="card-body pt-3">
                                                    <div className="fw-semibold fs-17 text-black mb-2">{item.title}</div>
                                                    {item.text}
                                                </div>
                                            </Link>
                                            <div className="card-footer">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Link to={`/profile/${item.profile.username}`} className={`dont-click d-flex align-items-center ${item.profile.verified ? 'verified' : ''}`}>
                                                        <div className="avatar avatar-rounded me-2">
                                                            <img src={base} className="image-cover" style={{backgroundImage: `url(${item.profile.image})`}} />
                                                        </div>
                                                        <div className="flex-fill">
                                                            <div className="fs-15 fw-semibold">{item.profile.name}</div>
                                                            <p className="mb-0 text-muted fs-11">@{item.profile.username}</p>
                                                        </div>
                                                    </Link>
                                                    {item.type=='free' &&<div className="fw-semibold text-success">Grátis</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                        </div>}
                    </div>}
                </Content>
            </div>
        </div>
    );
}
