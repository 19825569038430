import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import brLocale from '@fullcalendar/core/locales/pt-br';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Calendar() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const today = new Date().toISOString().replace(/T.*$/, '')

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [calendar, setCalendar] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post(`/calendar`, {}, config);
            setCalendar(response.data.data.calendar);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if(!signed){
            navigate('/login');
            return;
        }
        loadPage();
    }, []);

    function handleEventClick(clickInfo) {
        console.log(clickInfo.event);
        if (!clickInfo.event.url) {
            window.open('/gym-class?date=' + clickInfo.event.startStr + '&id=' + clickInfo.event.id, "_blank");
            return false;
        }
    }

    function CardShimmer(){
        return (<div className="card custom-card">
            <div className="card-img-top">
                <Shimmer source={base} rounded={0} />
            </div>
            <div className="card-body pt-3">
                <div className="fw-semibold fs-17 text-black mb-2"><Shimmer width={200} /></div>
                <Shimmer height={16} />
                <Shimmer width={120} height={16} />
            </div>

            <div className="card-footer">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="avatar avatar-rounded me-2">
                            <Shimmer source={base} rounded={100} />
                        </div>
                        <div className="flex-fill">
                            <div className="fs-15 fw-semibold"><Shimmer width={150} height={16} /></div>
                            <div className="mb-0 text-muted fs-11"><Shimmer width={100} height={12} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    {loading ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <h4><Shimmer width={240} height={40} /></h4>
                                        <hr className="border-secondary" />
                                    </div>
                                    <div className="col-xl-12">
                                        <Shimmer height={220} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mt-3">
                                        <Shimmer height={220} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mt-3">
                                        <Shimmer height={220} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mt-3">
                                        <Shimmer height={220} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mt-3">
                                        <Shimmer height={220} rounded={10} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 className="text-black" data-aos="fade-up" data-aos-delay="50">Agenda</h4>
                            </div>
                            <div className="col-xl-12 mt-4" data-aos="fade-up" data-aos-delay="100">
                                <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                    initialView='dayGridMonth'
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                    }}
                                    locale={brLocale}
                                    initialDate={today}
                                    navLinks={true}
                                    droppable={true}
                                    dayMaxEvents={true}
                                    expandRows={true}
                                    eventTimeFormat={{
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }}
                                    events={calendar}
                                    eventClick={handleEventClick}
                                />
                            </div>
                            <div className="col-xl-12 mt-4" data-aos="fade-up" data-aos-delay="150">
                                <h5 className="text-black">Legendas</h5>
                                <p className="fs-14 fw-semibold bg-subtitle" style={{backgroundColor: '#03968f'}}>Aulas</p>
                                <p className="fs-14 fw-semibold bg-subtitle" style={{backgroundColor: '#8c16e0'}}>Eventos</p>
                                <p className="fs-14 fw-semibold bg-subtitle" style={{backgroundColor: '#c33'}}>Streaming Ao Vivo</p>
                                <p className="fs-14 fw-semibold bg-subtitle" style={{backgroundColor: '#157ec9'}}>Aniversário</p>
                            </div>
                        </div>
                    </div>}
                </Content>
            </div>
        </div>
    );
}
