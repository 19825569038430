import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { MdOutlinePlayCircleFilled, MdOutlinePlayCircleOutline } from "react-icons/md";
import ReactPlayer from 'react-player';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';
import Comments from '../../components/Comments';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Courses() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { url } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [courses, setCourses] = useState([]);
    const [courses_modules, setCoursesModules] = useState([]);
    const [current_content, setCurrentContent] = useState([]);

    async function loadPage(){
        setLoading(true);
        try{
            const response = await api.post(`/courses`, {}, config);
            setCourses(response.data.data.courses);
            setCoursesModules([]);
            setCurrentContent([])
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadCourses(){
         setLoading(true);
        try{
            const response = await api.post(`/course/${url}`, {}, config);
            setCourses(response.data.data.courses);
            setCoursesModules(response.data.data.modules);
            if (response.data.data.modules[0].content[0] && response.data.data.modules[0].content.length > 0) {
                getContent(response.data.data.modules[0].content[0]);
            }
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function TagList({ tags }) {
        const tagArray = tags ? tags.split(',') : [];
        return (<>
            {tagArray.map((tag, index) => (
                <a key={index} href={`#tag-${tag}`} className="btn btn-sm btn-primary-light me-2">
                    {tag}
                </a>
            ))}</>
        );
    }

    function getContent(arr){
        setCurrentContent(arr);
    }

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
        if(url){
            loadCourses();
            seHasContent(true);
        }else{
            loadPage();
            seHasContent(false)
        }
    }, [url]);

    function CardShimmer(){
        return (<div className="card custom-card bg-dark overlay-card text-fixed-white">
            <Shimmer source={base} rounded={0} />
            <div className="card-img-overlay d-flex flex-column p-0 over-content-bottom">
                <div className="card-footer border-top-0">
                    <h6 className="fw-semibold mb-0 text-fixed-white"><Shimmer width={200} /></h6>
                </div>
            </div>
        </div>);
    }

    function numbers() {
        return Math.floor(Math.random() * (600 - 60 + 1)) + 60;
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    {loading ?
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <h4><Shimmer width={240} height={40} /></h4>
                                        <div className="row gy-4">
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                            <div className="col-xl-4"><Shimmer source={base} rounded={0} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>:
                        <div className="row">
                            <div className="col-xl-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-4 mt-4">
                                <CardShimmer />
                            </div>
                        </div>}
                    </div>:
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            
                            <div className="col-xl-12">
                                <Link to="/courses" className="btn btn-primary label-btn mb-4" data-aos="fade-in">
                                    <div className="label-btn-icon fs-20 me-2">
                                        <RiArrowLeftSLine />
                                    </div>
                                    VOLTAR
                                </Link>
                                <div className="pb-0" data-aos="fade-up">
                                    <h4 className="fw-semibold">{courses.title}</h4>
                                </div>
                            </div>

                            <div className="col-xl-8">
                                <div className="card custom-card" data-aos="fade-up" data-aos-delay="50">
                                    <div className="card-body pb-0">
                                        <h4 className="fw-semibold mb-0" data-aos="fade-up" data-aos-delay="100">{current_content?.title}</h4>
                                    </div>
                                    <div className="card-body post-content pb-0">
                                        <div className="mb-0 fs-15" data-aos="fade-up" data-aos-delay="150">
                                            <div className="embed-container mb-4">
                                                <ReactPlayer
                                                    url={current_content?.video}
                                                    controls={true}
                                                    playsinline={true}
                                                    playing={true}
                                                    frameBorder="0"
                                                    allowFullScreen
                                                />
                                            </div>
                                            <div dangerouslySetInnerHTML={{__html: current_content?.description}} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="row">
                                    
                                    {courses_modules.map((item, index) => (
                                    <div className="col-xl-12" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    {item.title}
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <ul className="list-group list-custom">
                                                    {Array.isArray(courses_modules[index].content) && <>
                                                    {courses_modules[index].content.map(item_link => (
                                                    <li className={`list-group-item ${current_content?.id===item_link.id ? ' play-active' : ''}`} key={item_link.id} onClick={() => {
                                                        getContent(item_link);
                                                    }}>
                                                        <div className="d-flex gap-2 flex-wrap align-items-center">
                                                            
                                                            <div className="list-play">
                                                                {current_content?.id===item_link.id ? <MdOutlinePlayCircleFilled /> : <MdOutlinePlayCircleOutline />}
                                                            </div>
                                                            <div className="flex-fill">
                                                                <h4 className="fs-15 fw-semibold mb-0 op-1-1">{item_link.title}</h4>
                                                            </div>
                                                            <div className="list-arrow">
                                                                <RiArrowRightSLine />
                                                            </div>
                                                        </div>
                                                    </li>))}
                                                    </>}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>))}
                                    
                                </div>
                            </div>
                            
                            {current_content?.comments==="yes" &&
                            <div className="col-xl-8" data-aos="fade-up" data-aos-delay="50">
                                <Comments type="posts" id={current_content?.id} />
                            </div>}
                            
                            
                        </div>:
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 className="text-black" data-aos="fade-up">Cursos</h4>
                            </div>
                            <div className="col-xl-12">
                                <div className="row gy-4">
                                    {courses.map((item, index) => (
                                    <div className="col-xl-4" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                        <Link to={`/courses/${item.url}`}>
                                            <div className="card custom-card overflow-hidden card-hover">
                                                <div className="ecommerce-sale-image">
                                                    <img src={base} className="card-img-top image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                    <div className="card-img-overlay d-flex flex-column p-4 text-fixed-white">
                                                        <p className="ecommerce-sales-calendar text-center mb-0">
                                                            <span className="d-block fs-15 lh-1">
                                                                <span className="fw-semibold">{item.total}</span> Aulas
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="text-black fs-17 fw-semibold">{item.title}</div>
                                                    <p className="mb-3">{item.text}</p>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="btn btn-primary btn-wave me-2 waves-effect waves-light">Acessar Curso</div>
                                                        <div className="fw-semibold text-success">R$ {item.price}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>))}
                                </div>
                            </div>
                        </div>}
                    </div>}
                </Content>
            </div>
        </div>
    );
}
