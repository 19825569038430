import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaPlay } from "react-icons/fa";
import { RiArrowLeftSLine } from "react-icons/ri";
import cep from 'cep-promise';
import InputMask from "react-input-mask";

import Shimmer from '../../components/Shimmer';

import Form from '../../components/Form';
import Card from '../../components/Card';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import logo from '../../assets/images/logo.png';

const initialCardState = {
    cardNumber: '#### #### #### ####',
    cardHolder: 'NOME COMPLETO',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    isCardFlipped: false
};

export default function Payment() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { id } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [card_state, setCardState] = useState(initialCardState);
    const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

    const [id_coupon, setIdCoupon] = useState('');
    const [has_code, setHasCode] = useState(false);
    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpf, setCpf] = useState('');
    const [doc, setDoc] = useState('');
    const [validate_CPF, setValidateCPF] = useState(false);
    const [phone, setPhone] = useState('');

    const [zipcode, setZipcode] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState(' ');
    const [neighborhood, setNeighborhood] = useState('');
    const [complement, setComplement] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    const [type, setType] = useState('nacional');

    const [installments, setInstallments] = useState(1);
    const [max_installments, setMaxInstallments] = useState(1);

    const [loading, setLoading] = useState(true);
    const [loading_page, setLoadingPage] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [error_code, setCodeError] = useState(false);
    const [error_code_message, setCodeErrorMessage] = useState('');
    const [cep_error, setCepError] = useState(false);
    const [cep_success, setCepSuccess] = useState(false);
    const [cep_error_message, setCepErrorMessage] = useState('');
    const [payment, setPayment] = useState([]);
    const [payment_method, setPaymentMethod] = useState('credit_card');

    const updateStateValues = useCallback(
        (keyName, value) => {
            setCardState({
                ...card_state,
                [keyName]: value || initialCardState[keyName]
            });
        },
        [card_state]
    );

    function ValidateCPF(value) {
        if(value==null){
            var cpf = '';
        }else{
            value = value.replace('.','');
            value = value.replace('.','');
            value = value.replace('_', '');
            var cpf = value.replace('-','');
        }
    
    
        while(cpf.length < 11) cpf = "0"+ cpf;
        var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
        var a = [];
        var b = new Number;
        var c = 11;
        var i, x, y;
        for (i=0; i<11; i++){
            a[i] = cpf.charAt(i);
            if (i < 9) b += (a[i] * --c);
        }
        if ((x = b % 11) < 2) { a[9] = 0 } else { a[9] = 11-x }
        b = 0;
        c = 11;
        for (y=0; y<10; y++) b += (a[y] * c--);
        if ((x = b % 11) < 2) { a[10] = 0; } else { a[10] = 11-x; }
        
        var has_return = true;
        if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) has_return = false;
        
        return has_return;
    }

    function changeValidateCPF(value){
        setCpf(value);
        setValidateCPF(ValidateCPF(value));
    }

    function searchZipcode(value) {

        if(value==""){
            return false;
        }
        const counter = value.replace(/_/ig, '');
        if(counter.length===9){
            cep(value).then(function (response) {
                setAddress(response.street);
                setNeighborhood(response.neighborhood);
                setCity(response.city);
                setState(response.state);
                setCepSuccess(true);
                setCepError(false);
                setCepErrorMessage('');
            }).catch(function (error) {
                setCepSuccess(false);
                setCepError(true);
                setCepErrorMessage(error.message);
            });
            document.getElementById('cepRef').blur();
        }
    }

    function zipcodeDigit(value){
        setZipcode(value);
    }

    function setDigitCoupon(value){
        setIdCoupon(value);
        setHasCode(false);
    }

    let formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCvv: useRef()
    };

    let focusFormFieldByKey = useCallback((key) => {
        formFieldsRefObj[key].current.focus();
    });

    // This are the references for the Card DIV elements.
    let cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef()
    };

    let onCardFormInputFocus = (_event, inputName) => {
        const refByName = cardElementsRef[inputName];
        setCurrentFocusedElm(refByName);
    };

    let onCardInputBlur = useCallback(() => {
        setCurrentFocusedElm(null);
    }, []);

    async function submitPayment(e){
        e.preventDefault();
        setLoadingPage(true);
        setCodeError(false);
        setCodeErrorMessage('');

        let card_expiration_year = card_state.cardYear.substring(2);
        let card_number = card_state.cardNumber;
        let card_cvv = card_state.cardCvv;
        let card_expiration_date = `${card_state.cardMonth}${card_expiration_year}`;
        let card_holder_name = card_state.cardHolder;
        let card_installments = installments;

        if(name=="" ||
            last_name=="" ||
            email=="" ||
            password=="" ||
            phone=="" ||
            zipcode=="" ||
            address=="" ||
            number=="" ||
            neighborhood=="" ||
            city=="" ||
            state==""
        ){
            setLoadingPage(false);
            setError(true);
            setErrorMessage("Preencha todos os campos!");
            return;
        }else if(ValidateCPF(cpf)==false){
            setLoadingPage(false);
            setError(true);
            setErrorMessage("CPF inválido!");
            return;
        }else if(card_number=="" || card_holder_name=="" || card_state.cardMonth=="" || card_state.cardYear=="" || card_cvv==""){
            setLoadingPage(false);
            setError(true);
            setErrorMessage("Preencha os dados do cartão!");
            return;
        }

        try{
            const response = await api.post(`/payment`, {
                payment_method,
                id_product: payment.id_product,
                paid_id: id,
                paid_type: payment.type,
                plan_title: payment.title_product,
                price: payment.price,
                name,
                last_name,
                phone,
                email,
                cpf,
                password,
                zipcode,
                address,
                number,
                complement,
                neighborhood,
                city,
                state,
                card_number,
                card_cvv,
                card_expiration_date,
                card_holder_name,
                card_installments,
                installments: max_installments
            });

            if(response.data.status=="error"){
                setLoading(false);
                setError(true);
                setErrorMessage(response.data.message);
                setLoadingPage(false);
                return;
            }
            
            setSuccess(true);
            setLoading(false);
            setError(false);
            setErrorMessage('');
            setLoadingPage(false);
        }catch(error){
            setLoading(false);
            setError(true);
            setErrorMessage(error.message);
            setLoadingPage(false);
        }

    }

    async function submitFreePayment(e){
        e.preventDefault();
        setLoadingPage(true);
        setCodeError(false);
        setCodeErrorMessage('');

        if(id_coupon==""){
            setLoadingPage(false);
            setError(true);
            setErrorMessage("Código inválido!");
            return;
        }

        if(type=="nacional"){
            if(name=="" ||
                last_name=="" ||
                email=="" ||
                password=="" ||
                phone==""
            ){
                setLoadingPage(false);
                setError(true);
                setErrorMessage("Preencha todos os campos!");
                return;
            }else if(ValidateCPF(cpf)==false){
                setLoadingPage(false);
                setError(true);
                setErrorMessage("CPF inválido!");
                return;
            }
        }else{
            if(name=="" ||
                last_name=="" ||
                email=="" ||
                password=="" ||
                phone=="" ||
                doc==""
            ){
                setLoadingPage(false);
                setError(true);
                setErrorMessage("Preencha todos os campos!");
                return;
            }
        }

        try{
            const response = await api.post(`/free-payment`, {
                id_coupon,
                id_product: payment.id_product,
                paid_id: id,
                paid_type: payment.type,
                plan_title: payment.title_product,
                price: 0,
                name,
                last_name,
                phone,
                email,
                cpf,
                doc,
                password,
                region: type
            });

            if(response.data.status=="error"){
                setLoading(false);
                setError(true);
                setErrorMessage(response.data.message);
                setLoadingPage(false);
                return;
            }
            
            setSuccess(true);
            setLoading(false);
            setError(false);
            setErrorMessage('');
            setLoadingPage(false);
        }catch(error){
            setLoading(false);
            setError(true);
            setErrorMessage(error.message);
            setLoadingPage(false);
        }

    }

    async function submitCoupon(e){
        e.preventDefault();
        setLoadingPage(true);
        try{
            const response = await api.post(`/coupon`, { id_coupon });
            
            if(response.data.status==="success"){
                setHasCode(true);
                setCodeError(false);
                setCodeErrorMessage('');
            }else{
                setHasCode(false);
                setCodeError(true);
                setCodeErrorMessage(response.data.message);
            }
            setLoading(false);
            setLoadingPage(false);
        }catch(error){
            setHasCode(false);
            setLoading(false);
            setLoadingPage(false);
            setCodeError(true);
            setCodeErrorMessage(error.message);
        }
    }

    async function loadPage(){
        setCodeError(false);
        setCodeErrorMessage('');
        try{
            const response = await api.get(`/payment/${id}`);
            setPayment(response.data.data.payment);
            setInstallments(response.data.data.payment.installments);
            setMaxInstallments(response.data.data.payment.installments);
            setLoading(false);
            setLoadingPage(false);
        }catch(err){
            setLoading(false);
            setLoadingPage(false);
        }
    }

    useEffect(() => {
        if(id){
            setLoading(true);
            loadPage();
        }else{
            window.location.href = 'https://planilhas.bruk.digital/14-dias-gratis';
        }
    }, [id]);

    return (
        <div className="landing-page-wrapper">
            {loading_page &&
            <div id="loader">
                <div className="loader-content"><div className="sbl-circ-path"></div></div>
            </div>}
            <header className="app-payment">
                <div className="container p-0">
                    <div className="landing-logo-container">
                        <div className="horizontal-logo">
                            <a href="/" className="header-logo">
                                <img src={logo} className="logo" />
                            </a>
                        </div>
                    </div>
                </div>
            </header>

            <div className="main-content landing-main">
                {!loading &&
                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-xl-6">
                                <h2 className="fw-semibold mb-2">PAGAMENTO</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-3">
                            <div className="col-lg-6">
                                <div className="card-form">
                                    {success ?
                                    <div className="card bg-white border-0">
                                        <div className="alert custom-alert alert-primary">
                                            <div className="text-center px-5 pb-0">
                                                <svg className="custom-alert-icon svg-primary" xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 24 24" width="1.5rem" fill="#000000"><path d="M0 0h24v24H0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>
                                                <h5>Tudo certo!</h5>
                                                {has_code ?
                                                <span className="text-black fs-16 fw-bold">Você receberá um e-mail com as instruções de login para o sistema BRUK Digital.</span>:
                                                <span className="text-black fs-16 fw-bold">Vamos processar seu pagamento, assim que for validado você receberá um e-mail com as instruções de login para o sistema BRUK Digital.</span>}
                                            </div>
                                        </div>
                                    </div>:
                                    <div className="card-form__inner">
                                        <div className="d-flex align-items-center justify-content-center text-center mb-3">
                                            <span className="text-white fs-20 fw-bold d-block">{payment.title_product}</span>
                                        </div>
                                        {!has_code &&
                                        <div className="d-flex align-items-center justify-content-center text-center mb-1">
                                            <span className="text-success fw-semibold fs-14">{payment.title}</span>
                                        </div>}
                                        <div className="d-flex align-items-center justify-content-center text-center mb-4">
                                            {has_code ?
                                            <span className="text-success fw-semibold fs-22">Gratuito</span>:
                                            <><span className="text-success fw-semibold fs-22">{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.price)}</span> <span className="text-white fw-semibold fs-13 mx-2"> / mês</span></>}
                                        </div>
                                        <div className="card-input divider-bottom">
                                            <span className="text-white fs-16 fw-bold mb-2">Tem um Código Promocional?</span>
                                            <div className="code-input">
                                                <input type="text" placeholder="Código" className="card-input__input" value={id_coupon} onChange={e => setDigitCoupon(e.target.value)} />
                                                <button className="btn btn-primary btn-lg btn-wave waves-effect waves-light" onClick={submitCoupon}>VERIFICAR</button>
                                            </div>
                                            {error_code &&
                                            <div className="d-grid">
                                                <div className="alert alert-dismissible alert-danger mt-3">{error_code_message}</div>
                                            </div>}
                                            {has_code &&
                                            <a className="a_link text-danger mt-2" onClick={() => {
                                                setHasCode(false);
                                                setIdCoupon('');
                                            }}>Remover Código</a>}
                                        </div>

                                        {has_code &&
                                        <div className="card-input">
                                            <select className="card-input__input -select" value={type} onChange={e => setType(e.target.value)}>
                                                <option value="nacional">Brasil</option>
                                                <option value="internacional">Exterior</option>
                                            </select>
                                        </div>}
                                        <div className="card-input card-form__group">
                                            <input type="text" placeholder="Nome" className="card-input__input" value={name} onChange={e => setName(e.target.value)} />
                                            <input type="text" placeholder="Sobrenome" className="card-input__input" value={last_name} onChange={e => setLastName(e.target.value)} />
                                        </div>
                                        <div className="card-input">
                                            <input type="email" placeholder="E-mail" className="card-input__input" value={email} onChange={e => setEmail(e.target.value)} />
                                        </div>
                                        <div className="card-input">
                                            <input type="password" placeholder="Senha" className="card-input__input" value={password} onChange={e => setPassword(e.target.value)} />
                                        </div>
                                        
                                        {(type=="internacional" && has_code) ?
                                        <>
                                            <div className="card-input">
                                                <input type="text" autoComplete="off" placeholder="ID / Passaporte" className="card-input__input" value={doc} onChange={e => setDoc(e.target.value)} />
                                            </div>
                                            <div className="card-input">
                                                <input type="text" placeholder="Telefone / Phone" className="card-input__input" value={phone} onChange={e => setPhone(e.target.value)} />
                                            </div>
                                        </>:
                                        <>
                                            <div className="card-input">
                                                <InputMask type="tel" autoComplete="off" placeholder="CPF" className="card-input__input" mask="999.999.999-99" value={cpf} onChange={e => changeValidateCPF(e.target.value)} />
                                                {(!validate_CPF && cpf!="") &&
                                                <div className="d-flex align-items-center justify-content-start mt-1">
                                                    <span className="text-danger">CPF inválido!</span>
                                                </div>}
                                            </div>
                                            <div className="card-input">
                                                <InputMask placeholder="Telefone" className="card-input__input" mask="(99) 99999-9999" value={phone} onChange={e => setPhone(e.target.value)} type="tel" />
                                            </div>
                                        </>}
                                        
                                        {!has_code ?
                                        <>
                                            <div className="card-input text-end">
                                                <InputMask autoComplete="off" id="cepRef" placeholder="CEP" className="card-input__input" mask="99999-999" value={zipcode} onKeyUp={e => searchZipcode(e.target.value)} onChange={e => zipcodeDigit(e.target.value)} type="tel" />
                                                <div className="d-flex align-items-center justify-content-between mt-1 text-end">
                                                    {cep_error ? <span className="text-danger">{cep_error_message}</span>:<span></span>}
                                                    <a className="text-success" target="_blank" href="http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm">Não sei o CEP</a>
                                                </div>
                                            </div>

                                            {cep_success &&
                                            <div className="card-form__row">
                                                <div className="card-form__col">
                                                    <div className="card-input card-form__group">
                                                        <input placeholder="Endereço" className="card-input__input" value={address} onChange={e => setAddress(e.target.value)} type="text" maxLength="30" />
                                                        <input placeholder="Número" className="card-input__input" value={number} onChange={e => setNumber(e.target.value)} type="text" />
                                                    </div>
                                                    <div className="card-input card-form__group">
                                                        <input placeholder="Complemento" className="card-input__input" value={complement} onChange={e => setComplement(e.target.value)} type="text" maxLength="30" />
                                                        <input placeholder="Bairro" className="card-input__input" value={neighborhood} onChange={e => setNeighborhood(e.target.value)} type="text" />
                                                    </div>
                                                    <div className="card-input card-form__group">
                                                        <input placeholder="Cidade" className="card-input__input" value={city} onChange={e => setCity(e.target.value)} type="text" />
                                                        <select className="card-input__input -select" value={state} onChange={e => setState(e.target.value)}>
                                                            <option value="">Estado</option>
                                                            <option value="AC">Acre</option>
                                                            <option value="AL">Alagoas</option>
                                                            <option value="AP">Amapá</option>
                                                            <option value="AM">Amazonas</option>
                                                            <option value="BA">Bahia</option>
                                                            <option value="CE">Ceará</option>
                                                            <option value="DF">Distrito Federal</option>
                                                            <option value="ES">Espírito Santo</option>
                                                            <option value="GO">Goiás</option>
                                                            <option value="MA">Maranhão</option>
                                                            <option value="MT">Mato Grosso</option>
                                                            <option value="MS">Mato Grosso do Sul</option>
                                                            <option value="MG">Minas Gerais</option>
                                                            <option value="PA">Pará</option>
                                                            <option value="PB">Paraíba</option>
                                                            <option value="PR">Paraná</option>
                                                            <option value="PE">Pernambuco</option>
                                                            <option value="PI">Piauí</option>
                                                            <option value="RJ">Rio de Janeiro</option>
                                                            <option value="RN">Rio Grande do Norte</option>
                                                            <option value="RS">Rio Grande do Sul</option>
                                                            <option value="RO">Rondônia</option>
                                                            <option value="RR">Roraima</option>
                                                            <option value="SC">Santa Catarina</option>
                                                            <option value="SP">São Paulo</option>
                                                            <option value="SE">Sergipe</option>
                                                            <option value="TO">Tocantins</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>}
                                            
                                            <div className="content-card">
                                                <Form
                                                    cardMonth={card_state.cardMonth}
                                                    cardYear={card_state.cardYear}
                                                    onUpdateState={updateStateValues}
                                                    cardNumberRef={formFieldsRefObj.cardNumber}
                                                    cardHolderRef={formFieldsRefObj.cardHolder}
                                                    cardDateRef={formFieldsRefObj.cardDate}
                                                    onCardInputFocus={onCardFormInputFocus}
                                                    onCardInputBlur={onCardInputBlur}
                                                >
                                                    <Card
                                                        cardNumber={card_state.cardNumber}
                                                        cardHolder={card_state.cardHolder}
                                                        cardMonth={card_state.cardMonth}
                                                        cardYear={card_state.cardYear}
                                                        cardCvv={card_state.cardCvv}
                                                        isCardFlipped={card_state.isCardFlipped}
                                                        currentFocusedElm={currentFocusedElm}
                                                        onCardElementClick={focusFormFieldByKey}
                                                        cardNumberRef={cardElementsRef.cardNumber}
                                                        cardHolderRef={cardElementsRef.cardHolder}
                                                        cardDateRef={cardElementsRef.cardDate}
                                                    ></Card>
                                                </Form>
                                                
                                                {payment.type=='normal' &&
                                                <div className="card-input">
                                                    <select className="card-input__input -select" value={installments} onChange={e => setInstallments(e.target.value)}>
                                                        <option value="1">À Vista por {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.price * max_installments)}</option>
                                                        <option value={max_installments}>{max_installments}x de {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.price)} - ({Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.price * max_installments)})</option>
                                                    </select>
                                                </div>}

                                            </div>
                                            <div className="d-grid">
                                                {error &&
                                                <div className="alert alert-dismissible alert-danger mt-3">{error_message}</div>}
                                                <button className="btn btn-primary btn-lg btn-wave waves-effect waves-light mt-3" onClick={submitPayment}>ASSINAR</button>
                                            </div>
                                        </>:
                                        <div className="d-grid">
                                            {error &&
                                            <div className="alert alert-dismissible alert-danger mt-3">{error_message}</div>}
                                            <button className="btn btn-primary btn-lg btn-wave waves-effect waves-light mt-3" onClick={submitFreePayment}>CONTINUAR</button>
                                        </div>}
                                    </div>}
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </section>}
            </div>

        </div>
    );
}