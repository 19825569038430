import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';

import AuthContext from '../../contexts/auth';

export default function Page404() {

    const { signed } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
    }, []);

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    <div className="error-page">
                        <div className="container-fluid">
                            <div className="row text-center align-items-center justify-content-center h-100">
                                <div className="col-xl-12">
                                    <p className="error-text mb-sm-0 mb-2">404</p>
                                    <p className="fs-18 fw-semibold mb-3">Oops, página não encontrada!</p>
                                    <p className="mb-0 op-7 mb-3">A página que tentou acessar não existe ou foi renomeada.</p>
                                    <Link to="/" className="btn btn-primary">VOLTAR</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
}
