import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaPlay } from "react-icons/fa";
import { RiMessage3Line, RiAddLine, RiInstagramLine, RiFacebookCircleFill, RiTwitterFill, RiLinkedinFill, RiTiktokFill, RiYoutubeFill, RiExternalLinkLine } from "react-icons/ri";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ReactPlayer from 'react-player';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Profile() {

    const { signed, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { id } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post(`/profile/${id}`, {}, config);
            setUsers(response.data.data.users);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if(!signed){
            navigate('/login');
            return;
        }
        if(id){
            loadPage();
        }else{
            navigate('/');
        }
    }, [id]);

    function TagList({ tags }) {
        const tagArray = tags ? tags.split(',') : [];
        return (<>
            {tagArray.map((tag, index) => (
                <a key={index} href={`#tag-${tag}`} className="btn btn-sm btn-primary-light me-2">
                    {tag}
                </a>
            ))}</>
        );
    }
    
    return (
        <div className="page-wrapper">
            
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                {loading ?
                                <div className="card custom-card overflow-hidden">
                                </div>:
                                <div className="card custom-card overflow-hidden">
                                    <div className="card-body p-0">
                                        <div className="d-sm-flex align-items-top p-4 border-bottom border-block-end-dashed main-profile-cover">
                                            <div>
                                                <span className="avatar avatar-xxl avatar-rounded me-3">
                                                    <img src={base} className="image-cover" style={{backgroundImage: `url(${users.image})`}} />
                                                </span>
                                            </div>
                                            <div className="flex-fill main-profile-info">
                                                <div className={`d-flex align-items-center justify-content-between ${users.verified ? 'verified-i' : ''}`}>
                                                    <h6 className="fw-semibold mb-0 text-fixed-white">{users.name}</h6>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <button className="btn btn-light label-btn mx-3">
                                                            <div className="label-btn-icon fs-20 me-2"><RiAddLine /></div>
                                                            Seguir
                                                        </button>
                                                        <Link to={`/messages/${users.id}`} className="btn btn-light label-btn">
                                                            <div className="label-btn-icon fs-20 me-2"><RiMessage3Line /></div>
                                                            Mensagem
                                                        </Link>
                                                    </div>
                                                </div>
                                                <p className="mb-2 text-muted text-fixed-white op-8">@{users.username}</p>
                                                <div className="d-flex mb-0">
                                                    <div className="me-4">
                                                        <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0">113</p>
                                                        <p className="mb-0 fs-11 op-8 text-fixed-white">Posts</p>
                                                    </div>
                                                    <div className="me-4">
                                                        <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0">12.2k</p>
                                                        <p className="mb-0 fs-11 op-8 text-fixed-white">Seguidores</p>
                                                    </div>
                                                    <div className="me-4">
                                                        <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0">128</p>
                                                        <p className="mb-0 fs-11 op-8 text-fixed-white">Seguindo</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {users.bio &&
                                        <div className="p-4 border-bottom border-block-end-dashed">
                                            <p className="fs-17 mb-2 fw-semibold">Bio:</p>
                                            <p className="fs-15 text-muted mb-0">
                                               {users.bio}
                                            </p>  
                                        </div>}
                                        <div className="p-4 border-bottom border-block-end-dashed">
                                            <p className="fs-17 mb-2 fw-semibold">Redes Sociais:</p>
                                            <div className="d-flex">
                                                {users.instagram &&
                                                <a href={users.instagram} target="_blank" className="btn btn-icon btn-social btn-instagram me-2">
                                                    <RiInstagramLine />
                                                </a>}
                                                {users.facebook &&
                                                <a href={users.facebook} target="_blank" className="btn btn-icon btn-social btn-facebook me-2">
                                                    <RiFacebookCircleFill />
                                                </a>}
                                                {users.twitter &&
                                                <a href={users.twitter} target="_blank" className="btn btn-icon btn-social btn-twitter me-2">
                                                    <RiTwitterFill />
                                                </a>}
                                                {users.linkedin &&
                                                <a href={users.linkedin} target="_blank" className="btn btn-icon btn-social btn-linkedin me-2">
                                                    <RiLinkedinFill />
                                                </a>}
                                                {users.youtube &&
                                                <a href={users.youtube} target="_blank" className="btn btn-icon btn-social btn-youtube me-2">
                                                    <RiYoutubeFill />
                                                </a>}
                                                {users.tiktok &&
                                                <a href={users.tiktok} target="_blank" className="btn btn-icon btn-social btn-tiktok me-2">
                                                    <RiTiktokFill />
                                                </a>}
                                                {users.site &&
                                                <a href={users.site} target="_blank" className="btn btn-icon btn-social btn-site me-2">
                                                    <RiExternalLinkLine />
                                                </a>}
                                            </div>
                                        </div>
                                        {users.tags &&
                                        <div className="p-4 border-bottom border-block-end-dashed">
                                            <p className="fs-17 mb-2 fw-semibold">Interesses:</p>
                                            <div>
                                                <TagList tags={users.tags} />
                                            </div>
                                        </div>}
                                        
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
}