import React, { useState, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { GoHomeFill } from "react-icons/go";
import { FaUser, FaClipboardList, FaBell, FaCalendarDays } from "react-icons/fa6";
import { BiSolidMessageDots } from "react-icons/bi";
import { RiFileList3Fill, RiShieldCheckFill } from "react-icons/ri";
import { PiClockCounterClockwiseBold } from "react-icons/pi";
import { HiMiniCog8Tooth } from "react-icons/hi2";

import AuthContext from '../contexts/auth';

import base from '../assets/images/blank-square.png';

export default function Header() {

    const { signed, user } = useContext(AuthContext);    
    const [loading, setLoading] = useState(true);

    return (
        <div className="header-container">
            {signed &&
            <div className="header-wrapper">
                <div className="header-avatar">
                    <img src={base} className="header-avatar-img image-cover" style={{backgroundImage: `url(${user.image})`}} />
                </div>
                <div className="header-content">
                    <div className="header-container-username">
                        <div className="header-name">
                            { user.name } { user.last_name }
                        </div>
                        <div className="header-username">
                            @{ user.username }
                        </div>
                    </div>
                    <div className="header-settings">
                        <NavLink to="/notifications" className="menu-icon dont-click" activeClassName="active">
                            <FaBell />
                        </NavLink>
                        <NavLink to="/calendar" className="menu-icon dont-click" activeClassName="active">
                            <FaCalendarDays />
                        </NavLink>
                        <NavLink to="/settings" className="menu-icon dont-click" activeClassName="active">
                            <HiMiniCog8Tooth />
                        </NavLink>
                    </div>
                    <div className="header-menu">
                        <NavLink to="/" className="menu-icon-text dont-click" activeClassName="active">
                            <div className="menu-item-icon"><GoHomeFill /></div>
                            <div className="menu-item-text">Home</div>
                        </NavLink>
                        <NavLink to="/account" className="menu-icon-text dont-click" activeClassName="active">
                            <div className="menu-item-icon"><FaUser /></div>
                            <div className="menu-item-text">Minha Conta</div>
                        </NavLink>
                        <NavLink to="/messages" className="menu-icon-text dont-click" activeClassName="active">
                            <div className="menu-item-icon"><BiSolidMessageDots /></div>
                            <div className="menu-item-text">Mensagens</div>
                        </NavLink>
                        <NavLink to="/contract" className="menu-icon-text dont-click" activeClassName="active">
                            <div className="menu-item-icon"><FaClipboardList /></div>
                            <div className="menu-item-text">Contrato</div>
                        </NavLink>
                    </div>
                </div>
            </div>}
        </div>
    );
}