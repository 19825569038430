import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri";
import ReactPlayer from 'react-player';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Comments from '../../components/Comments';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';
import base_video from '../../assets/images/blank-video.png';

export default function Streaming() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { url } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [streaming, setStreaming] = useState([]);

    async function loadPage(){
         setLoading(true);
        try{
            const response = await api.post(`/streamings`, {}, config);
            setStreaming(response.data.data.streaming);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadStreaming(){
         setLoading(true);
        try{
            const response = await api.post(`/streaming/${url}`, {}, config);
            setStreaming(response.data.data.streaming);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function TagList({ tags }) {
        const tagArray = tags ? tags.split(',') : [];
        return (<>
            {tagArray.map((tag, index) => (
                <a key={index} href={`#tag-${tag}`} className="btn btn-sm btn-primary-light me-2">
                    {tag}
                </a>
            ))}</>
        );
    }

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
        if(url){
            loadStreaming();
            seHasContent(true);
        }else{
            loadPage();
            seHasContent(false)
        }
    }, [url]);

    function CardShimmer(){
        return (<div className="card custom-card bg-dark overlay-card text-fixed-white">
            <Shimmer source={base_video} rounded={0} />
            <div className="card-img-overlay d-flex flex-column p-0 over-content-bottom">
                <div className="card-footer border-top-0">
                    <h6 className="fw-semibold mb-0 text-fixed-white"><Shimmer width={200} /></h6>
                </div>
            </div>
        </div>);
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    {loading ?
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <h4><Shimmer width={240} height={40} /></h4>
                                        <div className="row gy-4">
                                            <div className="col-xl-12"><Shimmer source={base} rounded={0} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="row">
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <Shimmer height={240} rounded={10} />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>:
                        <div className="row">
                            <div className="col-xl-6">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                            <div className="col-xl-6 mt-4">
                                <CardShimmer />
                            </div>
                        </div>}
                    </div>:
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-12">
                                <Link to="/streaming" className="btn btn-primary label-btn mb-4" data-aos="fade-in">
                                    <div className="label-btn-icon fs-20 me-2">
                                        <RiArrowLeftSLine />
                                    </div>
                                    VOLTAR
                                </Link>
                                <div className="pb-0" data-aos="fade-up">
                                    <h4 className="fw-semibold">
                                        {streaming.live && '🔴 '}
                                        {streaming.title}
                                    </h4>
                                </div>
                            </div>
                            
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-xl-8">
                                        <div className="card custom-card" data-aos="fade-up" data-aos-delay="50">
                                            <div className="card-body post-content pb-0">
                                                <div className="mb-0 fs-15" data-aos="fade-up" data-aos-delay="150">
                                                    <div className="embed-container mb-4">
                                                        <ReactPlayer
                                                            url={streaming?.video}
                                                            controls={true}
                                                            playsinline={true}
                                                            playing={true}
                                                            frameBorder="0"
                                                            allowFullScreen
                                                        />
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{__html: streaming?.description}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4">
                                        <div className="row">
                                            <div className="col-xl-12" data-aos="fade-up">
                                                <div className="card custom-card">
                                                    <div className="card-header">
                                                        <div className="card-title">
                                                        Autor
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <Link to={`/profile/${streaming.profile.username}`} className="d-flex align-items-center">
                                                                <div className="avatar avatar-rounded me-2">
                                                                    <img src={base} className="image-cover" style={{backgroundImage: `url(${streaming.profile.image})`}} />
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <div className="fs-15 fw-semibold">{streaming.profile.name}</div>
                                                                    <p className="mb-0 text-muted fs-11">@{streaming.profile.username}</p>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {streaming.live &&
                                            <div className="col-xl-12" data-aos="fade-up" data-aos-delay="50">
                                                <div className="card custom-card">
                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            Sobre o Streaming
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="list-group list-unstyled">
                                                            <li className="d-flex justify-content-between align-items-start">
                                                                <div className="text-muted">
                                                                    <div className="fw-semibold fs-16 text-black">Início da Live:</div>
                                                                    {streaming.started}
                                                                </div>
                                                            </li>
                                                            {streaming.price &&
                                                            <li className="d-flex justify-content-between align-items-start mt-4">
                                                                <div className="text-muted">
                                                                    <div className="fw-semibold fs-16 text-black">Pagamento:</div>
                                                                    <div className="fw-semibold fs-16 text-success">R$ {streaming.price}</div>
                                                                </div>
                                                            </li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-8" data-aos="fade-up" data-aos-delay="50">
                                <Comments type="products" id={streaming.id} />
                            </div>
                            
                        </div>:
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 className="text-black" data-aos="fade-up">Streaming</h4>
                            </div>
                            <div className="col-xl-12">
                                <div className="row gy-4">
                                    {streaming.map((item, index) => (
                                    <div className="col-xl-6" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                        <Link to={`/streaming/${item.url}`}>
                                            <div className="card custom-card overlay-card text-fixed-white card-hover">
                                                {item.live &&<div className="card-live">🔴 AO VIVO</div>}
                                                <img src={base_video} className="card-img image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                <div className="card-img-overlay d-flex flex-column p-0 over-content-bottom">
                                                    <div className="card-footer border-top-0">
                                                        <h6 className="fw-semibold mb-0 text-fixed-white">{item.title}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>))}
                                </div>
                            </div>
                        </div>}
                    </div>}
                </Content>
            </div>
        </div>
    );
}
