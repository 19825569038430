import React, { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";

import api from '../services/api';
import AuthContext from '../contexts/auth';

export default function AddPost(props) {

    const { token } = useContext(AuthContext);

    const navigate = useNavigate();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const refMention = useRef(null);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState('');
    
    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin();
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);
    
    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    const onSearchChange = useCallback(({ value }) => {
        handleInputChange(value);
    }, []);

    async function debouncedSearch(term) {
        try {
            const response = await api.post(`/mention?search=${term}`, {}, config);
            setSuggestions(defaultSuggestionsFilter(term, response.data.data.mentions));
        } catch (err) {
            console.error('Erro ao buscar usuários:', err);
        }
    }

    function handleInputChange(term) {
        setTimeout(() => {
            debouncedSearch(term);
        }, 300);
    }

    async function submitPost(e){
        e.preventDefault();
        setLoading(true);
        const text = editorState.getCurrentContent().getPlainText();
        try{
            if(text==""){
                return;
            }
            await api.post(`/send-post`, {text: text.trim()}, config);
            setEditorState(() =>
                EditorState.createEmpty()
            );
            navigate(0);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }
    
    return (
        <div className="card custom-card">
            <div className="card-header">
                <div className="card-title">
                    Publique na BRUK
                </div>
            </div>
            <div className="card-body">
                <div className="row gy-3">
                    <div className="col-xl-12">
                        <div className="form-control"
                            onClick={() => {
                                refMention?.current.focus();
                            }}
                        >
                            <Editor
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={setEditorState}
                                plugins={plugins}
                                ref={refMention}
                            />
                            <MentionSuggestions
                                open={open}
                                onOpenChange={onOpenChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchChange}
                                onAddMention={() => {
                                // get the mention object selected
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="text-end">
                    <button class="btn btn-primary-light mx-2"><MdOutlineAddPhotoAlternate /></button>
                    <button className="btn btn-primary-light" onClick={submitPost}>Publicar</button>
                </div>
            </div>
        </div>
    );
}