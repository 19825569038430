import React from 'react';
export default function Loader(props) {
    const { loading } = props;
    return (
        <>
            {loading &&
            <div id="loader">
                <div className="loader-content"><div className="sbl-circ-path"></div></div>
            </div>}
        </>
    );
}