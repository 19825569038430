import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';

import AuthContext from '../../contexts/auth';

export default function Privacy() {

    const { signed } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if(!signed){
            navigate('/login');
            return;
        }
    }, []);

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-10 text-justify">
                                <h2 className="mt-4 mb-4 text-black">POLÍTICA DE PRIVACIDADE</h2>
                                <p className="fs-17 fw-semibold mb-3"><span>A presente <strong>Pol&iacute;tica de Privacidade</strong> tem por finalidade demonstrar o compromisso da <strong>BRUK FIT</strong>, com a privacidade e a prote&ccedil;&atilde;o dos Dados Pessoais que coleta de <strong>Voc&ecirc;, </strong>estabelecendo as regras sobre coleta, registro, armazenamento, uso, compartilhamento, enriquecimento e elimina&ccedil;&atilde;o dos seus dados coletados, dentro do escopo dos servi&ccedil;os e funcionalidades da bruk.fit/bruk.digital (&ldquo;<strong>Plataforma</strong>&rdquo;), de acordo com as leis em vigor, com transpar&ecirc;ncia e clareza junto a <strong>voc&ecirc; </strong>e ao mercado em geral.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Como condi&ccedil;&atilde;o para acesso e uso das funcionalidades exclusivas da <strong>Plataforma, voc&ecirc;</strong> declara que fez a leitura completa e atenta da presente <strong>Pol&iacute;tica de Privacidade, </strong>estando plenamente ciente, conferindo, assim, sua livre e expressa concord&acirc;ncia com os termos aqui estipulados, autorizando a obten&ccedil;&atilde;o dos dados e informa&ccedil;&otilde;es aqui mencionados, bem como sua utiliza&ccedil;&atilde;o para os fins abaixo especificados. Caso <strong>voc&ecirc;</strong> n&atilde;o esteja de acordo com estas Diretivas, <strong>voc&ecirc;</strong> dever&aacute; descontinuar o acesso.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mt-5 mb-3 text-black"><strong><span>1.   COLETA E USO DE DADOS PESSOAIS E REGISTRO DE ATIVIDADES</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.1.   Coleta de dados pessoais.</span></strong><span> Os dados s&atilde;o coletados a partir da sua ades&atilde;o volunt&aacute;ria ao uso da <strong>Plataforma</strong>, al&eacute;m de dados coletados automaticamente, e incluem:</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>DADOS CADASTRAIS:</span><span></span><span>Nome; n&uacute;mero RG; n&uacute;mero CPF;<em> e-mail</em>; endere&ccedil;o de cobran&ccedil;a; telefone fixo; telefone celular; tipo sangu&iacute;neo.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Finalidades:</span></p>
                                <ul className="fs-17 fw-semibold mb-3">
                                    <li><span>Identificar e autenticar <strong>voc&ecirc;.</strong></span></li>
                                    <li><span>Atender <strong>voc&ecirc;</strong>! Cumprir as obriga&ccedil;&otilde;es decorrentes do uso dos servi&ccedil;os da <strong>Plataforma.</strong></span></li>
                                    <li><span>Para cria&ccedil;&atilde;o de perfil de consumo e realiza&ccedil;&atilde;o de publicidade de ofertas e servi&ccedil;os da empresa BRUK Fit, sempre que poss&iacute;vel, de forma anonimizada.</span></li>
                                    <li><span>Proteger <strong>voc&ecirc;</strong>! Realizar preven&ccedil;&atilde;o a fraudes, prote&ccedil;&atilde;o ao cr&eacute;dito e riscos associados, cumprimento de obriga&ccedil;&otilde;es legais, regulat&oacute;rias e execu&ccedil;&atilde;o do contrato.</span></li>
                                </ul>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>DADOS IDENTIFICA&Ccedil;&Atilde;O DIGITAL:</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Endere&ccedil;o IP e Porta L&oacute;gica de Origem; registros de intera&ccedil;&otilde;es com a <strong>Plataforma; </strong>telas acessadas; dispositivo (vers&atilde;o do sistema operacional); geolocaliza&ccedil;&atilde;o.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Finalidades:</span></p>
                                <ul className="fs-17 fw-semibold mb-3">
                                    <li><span>Cumprir com obriga&ccedil;&otilde;es legais! Cumprir obriga&ccedil;&atilde;o estabelecida pelo Marco Civil da Internet - Lei n. 12.965/2014</span></li>
                                    <li><span>Proteger <strong>voc&ecirc;</strong>! Realizar preven&ccedil;&atilde;o a fraudes, prote&ccedil;&atilde;o ao cr&eacute;dito e riscos associados, cumprimento de obriga&ccedil;&otilde;es legais, regulat&oacute;rias e execu&ccedil;&atilde;o do contrato.</span></li>
                                </ul>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.2    Hip&oacute;teses de compartilhamento dos Dados. </span></strong><span>Os Dados coletados e as atividades registradas podem ser compartilhados:</span></p>
                                <ol className="fs-17 fw-semibold mb-3">
                                    <li><span>Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determina&ccedil;&atilde;o legal, requerimento, requisi&ccedil;&atilde;o ou ordem judicial;</span></li>
                                    <li><span>De forma autom&aacute;tica, em caso de movimenta&ccedil;&otilde;es societ&aacute;rias, como fus&atilde;o, aquisi&ccedil;&atilde;o e incorpora&ccedil;&atilde;o;</span></li>
                                    <li><span>Com empresas parceiras e fornecedores autorizados para atendimento das finalidades informadas nesta Pol&iacute;tica, a exemplo de prestadores de servi&ccedil;os de fotografia e filmagem, atividades extracurriculares, transporte; e</span></li>
                                    <li><span>Com empresas da <strong>BRUK FIT</strong>, do qual a <strong>BRUK FIT </strong>faz parte, sempre em observ&acirc;ncia &agrave;s diretrizes de seguran&ccedil;a e prote&ccedil;&atilde;o de Dados e para atender as finalidades desta <strong>Pol&iacute;tica</strong>.</span></li>
                                </ol>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.2.1. </span></strong><span>  Nestes casos, a <strong>BRUK FIT</strong> compartilhar&aacute; o m&iacute;nimo de informa&ccedil;&otilde;es necess&aacute;rias para atingir sua finalidade.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.3.   Subcontrata&ccedil;&atilde;o.</span></strong><span> A <strong>BRUK FIT</strong> poder&aacute; subcontratar o servi&ccedil;o de processamento e armazenamento de dados <strong>(Operador de Dados)</strong>, de forma que <strong>voc&ecirc;</strong> <strong>EST&Aacute; CIENTE </strong>sobre<strong> </strong>o acesso e o tratamento de Dados Pessoais por terceiros, prestadores de servi&ccedil;os, cuja contrata&ccedil;&atilde;o tenha por objeto garantir a efici&ecirc;ncia dos servi&ccedil;os a serem prestados.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.3.1.  </span></strong><span> A <strong>BRUK FIT</strong> se compromete a subcontratar servi&ccedil;os de processamento e armazenamento de dados somente de empresas com a respectiva especialidade, garantindo todos os direitos do Titular dos Dados e impondo regras e responsabilidades ao Operador subcontratado.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.4.   Seguran&ccedil;a dos Dados. </span></strong><span>A <strong>BRUK FIT </strong>envidar&aacute; seus melhores esfor&ccedil;os para prote&ccedil;&atilde;o da informa&ccedil;&atilde;o, aplicando as medidas de prote&ccedil;&atilde;o administrativa e t&eacute;cnica necess&aacute;rias e dispon&iacute;veis &agrave; &eacute;poca, exigindo de seus fornecedores o mesmo n&iacute;vel aceit&aacute;vel de Seguran&ccedil;a da Informa&ccedil;&atilde;o, com base em melhores pr&aacute;ticas de mercado, a partir de cl&aacute;usulas contratuais.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.5.   Servidores de armazenamento</span></strong><span>. Os dados coletados ser&atilde;o armazenados em servidores Google administrados pela <strong>BRUK FIT </strong>localizados no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (<em>cloud computing</em>), o que enseja, neste &uacute;ltimo caso, transfer&ecirc;ncia ou processamento dos dados fora do Brasil, cumprindo disposi&ccedil;&otilde;es sobre transfer&ecirc;ncia internacional de dados conforme o artigo 33 da Lei Geral de Prote&ccedil;&atilde;o de Dados ou demais normas aplic&aacute;veis.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.6.   Veracidade dos dados.</span></strong><span> A <strong>BRUK FIT</strong> n&atilde;o &eacute; respons&aacute;vel pela precis&atilde;o, veracidade ou falta dela nas informa&ccedil;&otilde;es prestadas por <strong>voc&ecirc; </strong>ou pela sua desatualiza&ccedil;&atilde;o, ou pelos documentos encaminhados, sendo <strong>voc&ecirc; </strong>o respons&aacute;vel por prest&aacute;-las com exatid&atilde;o e/ou atualiz&aacute;-las.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.6.1. </span></strong><span>  A <strong>BRUK FIT </strong>n&atilde;o se obriga a processar ou tratar quaisquer dos seus dados se houver raz&otilde;es para crer que tal processamento ou tratamento possa imputar &agrave;s empresas qualquer infra&ccedil;&atilde;o de qualquer lei aplic&aacute;vel ou que a <strong>Plataforma </strong>esteja sendo utilizada, sob crit&eacute;rio exclusivo da<strong> BRUK FIT</strong>, para quaisquer fins ilegais, il&iacute;citos ou contr&aacute;rios &agrave; moralidade.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.6.2.   Tecnologias empregadas. </span></strong><span>A <strong>BRUK FIT </strong>pode fazer uso de tecnologias como<em> cookies, </em>cabendo a <strong>voc&ecirc;</strong> configurar o seu navegador de internet caso deseje bloque&aacute;-los. Nesta hip&oacute;tese, algumas funcionalidades da <strong>Plataforma </strong>poder&atilde;o ser limitadas.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.6.3.</span></strong><span>   Todas as tecnologias utilizadas respeitar&atilde;o sempre a legisla&ccedil;&atilde;o vigente e os termos desta <strong>Pol&iacute;tica.</strong></span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.7.  <em> Links </em>de terceiros. </span></strong><span>&Eacute; importante destacar que, uma vez na <strong>Plataforma</strong>,<strong> voc&ecirc;</strong> poder&aacute; ser conduzido, via <em>link,</em> a portais ou outras plataformas, que poder&atilde;o coletar suas informa&ccedil;&otilde;es e ter sua pr&oacute;pria Pol&iacute;tica de Privacidade, cabendo a <strong>voc&ecirc; </strong>l&ecirc;-las ou rejeit&aacute;-las.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.8.   Altera&ccedil;&otilde;es do consentimento</span></strong><span>. <strong>Voc&ecirc;</strong> poder&aacute; alterar suas concess&otilde;es de consentimento, conceder novas permiss&otilde;es ou requerer a revoga&ccedil;&atilde;o do seu consentimento para as permiss&otilde;es atuais por meio dos <strong>Canais de Atendimento </strong>da<strong> BRUK FIT</strong>, sendo avisado das consequ&ecirc;ncias que a retirada de consentimento poder&aacute; causar.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.9.   Base de dados. </span></strong><span>A base de dados formada por meio da coleta de dados &eacute; de propriedade e responsabilidade da <strong>BRUK FIT</strong>, sendo que seu uso, acesso e compartilhamento, quando necess&aacute;rios, ser&atilde;o feitos dentro dos limites e prop&oacute;sitos descritos nesta<strong> Pol&iacute;tica.</strong></span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>1.10.   Acesso &agrave; base de dados. </span></strong><span>O acesso aos dados tratados &eacute; restrito apenas a profissionais devidamente autorizados pela <strong>BRUK FIT</strong>, sendo que seu uso, acesso e compartilhamento, quando necess&aacute;rios, estar&atilde;o de acordo com as finalidades descritas nesta <strong>Pol&iacute;tica.</strong></span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span> </span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span> </span></strong></p>
                                <p className="fs-17 fw-semibold mt-5 mb-3 text-black"><strong><span>2.   ARMAZENAMENTO DOS DADOS E REGISTROS</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>2.1.   Armazenamento dos dados</span></strong><span>. Os Dados Pessoais coletados e os registros de atividades s&atilde;o armazenados em ambiente seguro e controlado pelo prazo exigido por lei, considerando:</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>DADOS PESSOAIS - CADASTRAIS:</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Prazo de armazenamento: Durante o per&iacute;odo em que estes forem pertinentes ao alcance das finalidades listadas neste instrumento.</span><span></span><span>Fundamento Legal: Art. 12 e 34 do C&oacute;digo de Defesa do Consumidor.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>DADOS PESSOAIS - IDENTIFICA&Ccedil;&Atilde;O DIGITAL:</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Prazo de armazenamento: 6 meses.</span><span></span><span>Fundamento Legal: Art. 15, Marco Civil da Internet.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>DADOS PESSOAIS - OUTROS:</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Prazo de armazenamento: Enquanto durar a rela&ccedil;&atilde;o e n&atilde;o houver pedido de apagamento ou revoga&ccedil;&atilde;o de consentimento.</span><span></span><span>Fundamento Legal: Art. 9, Inciso II da Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>2.2.   Exclus&atilde;o dos dados.</span></strong><span> Os dados poder&atilde;o ser apagados antes desse prazo, caso <strong>voc&ecirc;</strong> solicite. No entanto, pode ocorrer de os dados precisarem ser mantidos por per&iacute;odo superior, nos termos do artigo 16 da Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais, para cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria, cumprimento do contrato, estudo por &oacute;rg&atilde;o de pesquisa (garantindo, quando poss&iacute;vel, a anonimiza&ccedil;&atilde;o dos Dados Pessoais), transfer&ecirc;ncia a terceiro (respeitados os requisitos de tratamento de dados dispostos na mesma lei). Findos o prazo e a necessidade legal, ser&atilde;o exclu&iacute;dos com uso de m&eacute;todos de descarte seguro, ou utilizados de forma anonimizada para fins estat&iacute;sticos.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mt-5 mb-3 text-black"><strong><span>3.   DIREITOS DO TITULAR DOS DADOS PESSOAIS</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>3.1.   Exibi&ccedil;&atilde;o e retifica&ccedil;&atilde;o de dados</span></strong><span>. <strong>Voc&ecirc; </strong>pode solicitar a exibi&ccedil;&atilde;o ou retifica&ccedil;&atilde;o de seus Dados Pessoais por meio dos <strong>Canais de Atendimento</strong>.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>3.2.   Limita&ccedil;&atilde;o, oposi&ccedil;&atilde;o e exclus&atilde;o de dados. </span></strong><span>Pelos <strong>Canais de Atendimento</strong>, ser&aacute; poss&iacute;vel tamb&eacute;m:</span></p>
                                <ul className="fs-17 fw-semibold mb-3">
                                    <li><span>requerer a limita&ccedil;&atilde;o, anonimiza&ccedil;&atilde;o do uso dos Dados Pessoais;</span></li>
                                    <li><span>manifestar sua oposi&ccedil;&atilde;o ao uso dos Dados Pessoais;</span></li>
                                    <li><span>solicitar a exclus&atilde;o de seus Dados Pessoais, desde que decorrido o prazo legal m&iacute;nimo relacionado &agrave; guarda de dados; ou</span></li>
                                    <li><span>reivindicar a portabilidade dos dados a outro prestador de servi&ccedil;os do mesmo ramo, mediante requisi&ccedil;&atilde;o expressa, de acordo com a regulamenta&ccedil;&atilde;o da autoridade nacional.</span></li>
                                </ul>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>3.2.1.</span></strong><span>   Se <strong>voc&ecirc;</strong> retirar seu consentimento para finalidades fundamentais ao regular funcionamento da <strong>Plataforma,</strong> os servi&ccedil;os e funcionalidades poder&atilde;o ficar indispon&iacute;veis para <strong>Voc&ecirc;.</strong></span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span> </span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span> </span></strong></p>
                                <p className="fs-17 fw-semibold mt-5 mb-3 text-black"><strong><span>4.   DISPOSI&Ccedil;&Otilde;ES GERAIS</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>4.1   Encarregado de Dados. </span></strong><span>Para todos os efeitos de tratamento de dados, conforme disposto nos artigos 5, inciso VIII e 41, &sect;1&ordm; da Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais, declara a <strong>BRUK FIT</strong> que possui encarregado de dados, podendo ser contatado pelos <strong>Canais de Atendimento</strong> dispon&iacute;veis no <strong><em>site</em>.</strong></span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>4.2.   Atualiza&ccedil;&atilde;o dos Termos. </span></strong><span>A <strong>BRUK FIT</strong> reserva a si o direito de alterar o teor desta <strong>Pol&iacute;tica de Privacidade</strong> a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequa&ccedil;&atilde;o e conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha for&ccedil;a jur&iacute;dica equivalente, cabendo a <strong>voc&ecirc; </strong>verific&aacute;-la sempre que efetuar o acesso &agrave; <strong>Plataforma</strong>.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>4.2.1.   Ocorrendo atualiza&ccedil;&otilde;es neste documento e que demandem nova coleta de consentimento, a BRUK FIT notificar&aacute; voc&ecirc; pelos meios de contato que voc&ecirc; forneceu.</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>4.3.   Canais de atendimento.</span></strong><span> Em caso de qualquer d&uacute;vida com rela&ccedil;&atilde;o &agrave;s disposi&ccedil;&otilde;es constantes desta <strong>Pol&iacute;tica de Privacidade</strong>, <strong>voc&ecirc; </strong>poder&aacute; entrar em contato com a<strong> BRUK FIT </strong>por meio do <strong>Canal de Atendimento</strong> no <em>site</em> www.bruk.fit.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>4.4.   Nulidade. </span></strong><span>Caso alguma disposi&ccedil;&atilde;o desta <strong>Pol&iacute;tica de Privacidade</strong> seja considerada ilegal ou ileg&iacute;tima por autoridade da localidade em que <strong>voc&ecirc;</strong> resida ou da sua conex&atilde;o &agrave; internet, as demais condi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e efeito.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>4.5.   Comunica&ccedil;&atilde;o. Voc&ecirc;</span></strong><span> reconhece que toda comunica&ccedil;&atilde;o realizada por <em>e-mail</em> (aos endere&ccedil;os informados no seu cadastro), SMS, aplicativos de comunica&ccedil;&atilde;o instant&acirc;nea ou qualquer outra forma digital e virtual tamb&eacute;m s&atilde;o v&aacute;lidas como prova documental, sendo eficazes e suficientes para a divulga&ccedil;&atilde;o de qualquer assunto que se refira aos servi&ccedil;os prestados pela <strong>BRUK FIT</strong>, bem como &agrave;s condi&ccedil;&otilde;es de sua presta&ccedil;&atilde;o ou a qualquer outro assunto nele abordado, ressalvadas as disposi&ccedil;&otilde;es expressamente diversas previstas nesta <strong>Pol&iacute;tica de Privacidade.</strong></span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span> </span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span> </span></strong></p>
                                <p className="fs-17 fw-semibold mt-5 mb-3 text-black"><strong><span>5.   LEI APLIC&Aacute;VEL E JURISDI&Ccedil;&Atilde;O</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>5.1.   Lei aplic&aacute;vel e foro</span></strong><span>. A presente <strong>Pol&iacute;tica de Privacidade </strong>ser&aacute; interpretada segundo a legisla&ccedil;&atilde;o brasileira, no idioma portugu&ecirc;s, sendo eleito o foro da Comarca de Petr&oacute;polis para dirimir qualquer lit&iacute;gio ou controv&eacute;rsia envolvendo o presente documento, salvo ressalva espec&iacute;fica de compet&ecirc;ncia pessoal, territorial ou funcional pela legisla&ccedil;&atilde;o aplic&aacute;vel.</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mt-5 mb-3 text-black"><strong><span>6.   GLOSS&Aacute;RIO</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>6.1. </span></strong><span>  Para fins deste documento, devem se considerar as seguintes defini&ccedil;&otilde;es e descri&ccedil;&otilde;es para seu melhor entendimento:</span></p>
                                <ul className="fs-17 fw-semibold mb-3">
                                    <li><strong><span>Dados Pessoais: </span></strong><span>informa&ccedil;&otilde;es relacionadas a uma pessoa natural identificada ou identific&aacute;vel;</span></li>
                                    <li><strong><em><span>Cloud Computing</span></em></strong><span>: ou computa&ccedil;&atilde;o em nuvem, &eacute; tecnologia de virtualiza&ccedil;&atilde;o de servi&ccedil;os constru&iacute;da a partir da interliga&ccedil;&atilde;o de mais de um servidor por meio de uma rede de informa&ccedil;&atilde;o comum (exemplo: internet), com objetivo de reduzir custos e aumentar disponibilidade dos servi&ccedil;os sustentados;</span></li>
                                    <li><strong><span>Plataforma: </span></strong><span>designa os endere&ccedil;os eletr&ocirc;nicos www.bruk.fit/www.bruk.digital e seus subdom&iacute;nios;</span></li>
                                    <li><strong><span>Encarregado:</span></strong><span> pessoa indicada pelo controlador e operador para atuar como canal de comunica&ccedil;&atilde;o entre o controlador, titulares dos dados e a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados;</span></li>
                                    <li><strong><span>Controlador:</span></strong><span> pessoa natural ou jur&iacute;dica, de direito p&uacute;blico ou privado, a quem competem as decis&otilde;es referentes ao tratamento de Dados Pessoais;</span></li>
                                    <li><strong><span>Operador:</span></strong><span> pessoa natural ou jur&iacute;dica, de direito p&uacute;blico ou privado, que realiza o tratamento de Dados Pessoais em nome do controlador;</span></li>
                                    <li><strong><span>Autoridade nacional:</span></strong><span> &oacute;rg&atilde;o da Administra&ccedil;&atilde;o P&uacute;blica respons&aacute;vel por zelar, implementar e fiscalizar o cumprimento da Lei Geral de Prote&ccedil;&atilde;o de Dados em todo o territ&oacute;rio nacional;</span></li>
                                    <li><strong><em><span>Cookies</span></em></strong><strong><span>: </span></strong><span>arquivos enviados pelo servidor da<strong> BRUK FIT</strong> para o seu dispositivo m&oacute;vel ou <em>desktop</em>, com a finalidade de identificar o dispositivo e obter dados de acesso, permitindo, dessa forma, personalizar a sua navega&ccedil;&atilde;o na <strong>Plataforma</strong>, de acordo com o seu perfil;</span></li>
                                    <li><strong><span>IP: </span></strong><span>abreviatura de <em>Internet Protocol</em>. &Eacute; um conjunto alfanum&eacute;rico que identifica o seu dispositivo na internet; e</span></li>
                                    <li><strong><span>Voc&ecirc;: </span></strong><span>pessoa f&iacute;sica ou jur&iacute;dica que acessa e/ou utiliza as funcionalidades da <strong>Plataforma.</strong></span></li>
                                </ul>
                                <p className="fs-17 fw-semibold mb-3"><span> </span></p>
                                <p className="fs-17 fw-semibold mb-3"><strong><span>BRUK Fit Holding LTDA</span></strong></p>
                                <p className="fs-17 fw-semibold mb-3"><span>CNPJ: 45.312.916/0001-15</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>Avenida Belis&aacute;rio Leite de Andrade Neto, 138, Barra da Tijuca, Rio de Janeiro &ndash; RJ</span></p>
                                <p className="fs-17 fw-semibold mb-3"><span>CEP: 22621-270</span></p>
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
}
