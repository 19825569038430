import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaPlay } from "react-icons/fa";
import { RiArrowLeftSLine } from "react-icons/ri";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ReactPlayer from 'react-player';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';
import AuthContext from '../../contexts/auth';

import base from '../../assets/images/blank-square.png';

export default function Program() {

    const { signed, user, token } = useContext(AuthContext);
    const navigate = useNavigate();

    const { id } = useParams();

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [program, setProgram] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState('');

    async function loadPage(){
        seHasContent(false);
        try{
            const response = await api.post(`/program/${id}`, {}, config);
            setProgram(response.data.data.program);
            setPrograms(response.data.data.programs);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if(!signed){
            navigate('/login?redirect=/programs');
            return;
        }
        if(id){
            loadPage();
        }else{
            navigate('/programs');
        }
    }, [id]);

    function playVideo(url){
        setVideo(url);
        setOpen(true);
    }

    const renderTooltip = (props) => (
        <Tooltip className="tooltip-primary" {...props}>
            Assistir exemplo do exercício.
        </Tooltip>
    );

    return (
        <div className="page-wrapper">
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    {
                        type: "video",
                        src: video,
                    },
                ]}
                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
                carousel={{ finite: true }}
                render={{
                    buttonPrev: () => null,
                    buttonNext: () => null,
                    slide: ({ slide, rect }) =>
                      slide.type === "video" ? (
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="embed-container">
                                        <ReactPlayer
                                            url={slide.src}
                                            onEnded={() => setOpen(false)}
                                            controls={true}
                                            playsinline={true}
                                            playing={open}
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                      ) : undefined
                  }}
            />
            <Header />
            <div className="content-wrapper">
                <Sidebar />
                <Content>
                    {loading ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-xl-3">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 mb-4">
                                <Link to={`/programs/${program.id}`} className="btn btn-primary label-btn" data-aos="fade-up">
                                    <div className="label-btn-icon fs-20 me-2">
                                        <RiArrowLeftSLine />
                                    </div>
                                    VOLTAR
                                </Link>
                            </div>
                            {program.buy=='yes' ?
                            <>
                                <div className="col-xl-12">
                                    <h4 className="text-black" data-aos="fade-up" data-aos-delay="50">{program.title}: <small>{program.title_sequence}</small></h4>
                                    <div className="fs-15" data-aos="fade-up" data-aos-delay="100" dangerouslySetInnerHTML={{__html: program.text}} />
                                    <div className="fs-15" data-aos="fade-up" data-aos-delay="150" dangerouslySetInnerHTML={{__html: `<strong class="fs-16">Observações:</strong> ${program.observations}`}} />
                                    <hr data-aos="fade-up" data-aos-delay="150" className="border-secondary" />
                                </div>
                                <div className="col-xl-12" data-aos="fade-up" data-aos-delay="200">
                                    <h5 className="text-black">Blocos:</h5>
                                    {programs.map((item, index) => (
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="card custom-card">
                                                <div className="card-header">
                                                    <div className="card-title d-flex align-items-center">
                                                        <span className="text-black fs-16">{item.title}</span>
                                                        <span className="mx-3 badge bg-primary fs-13">{item.amount} {item.amount==1 ? 'Round':'Rounds'}</span>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="content-text-program" dangerouslySetInnerHTML={{__html: item.text}} />
                                                    <h6 className="text-black mt-3">Exercícios:</h6>
                                                    <div className="row">
                                                        <div className="col-xl-12 mt-2">
                                                            {Array.isArray(programs[index].items) && <>
                                                            {programs[index].items.map((item_e, index_e) => (
                                                            <div className="mx-4">
                                                                
                                                                {item_e.type=='free' ?
                                                                <div className="d-flex align-items-start flex-column">
                                                                    <div className="mb-2">
                                                                        <h6 className="set-space text-black fs-16 mb-0">
                                                                            <span className="text-primary fs-17">{ item_e.title!=null && item_e.title }</span>
                                                                        </h6>
                                                                        {item_e.video!=null &&
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={renderTooltip}
                                                                        >
                                                                            <div className="btn-play" onClick={() => playVideo(item_e.video)}>
                                                                                <FaPlay />
                                                                            </div>
                                                                        </OverlayTrigger>}
                                                                    </div>
                                                                    <div className="mx-4" dangerouslySetInnerHTML={{__html: item_e.text}} />
                                                                </div>:
                                                                <div className="d-flex align-items-center">
                                                                    <h6 className="set-space text-black fs-16 mb-0">
                                                                        <span className="text-primary fs-17">{ item_e.title!=null && item_e.title }</span>{ item_e.amount!=null && ' - ' + item_e.amount } {item_e.value!=null && ' - ' + item_e.value}
                                                                    </h6>
                                                                    {item_e.video!=null &&
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={renderTooltip}
                                                                    >
                                                                        <div className="btn-play" onClick={() => playVideo(item_e.video)}>
                                                                            <FaPlay />
                                                                        </div>
                                                                    </OverlayTrigger>}
                                                                </div>}

                                                                {index_e !== programs[index].items.length -1 && <hr className="border-secondary" />}
                                                            </div>))}</>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-none border-top-0">

                                                </div>
                                            </div>
                                        </div>
                                    </div>))}
                                </div>
                            </>:
                            <>
                                <div className="col-xl-12">
                                    <h4 className="text-black" data-aos="fade-up" data-aos-delay="50">{programs.title}</h4>
                                    <p className="fs-15" data-aos="fade-up" data-aos-delay="100">Você ainda não tem acesso a esse Programa</p>
                                    <a href="https://planilhas.bruk.digital/14-dias-gratis" target="_blank" className="btn btn-primary" data-aos="fade-up" data-aos-delay="150">
                                        Escolher um Plano
                                    </a>
                                </div>
                            </>}

                        </div>
                    </div>}
                </Content>
            </div>
        </div>
    );
}