import React, { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';

import api from '../services/api';
import AuthContext from '../contexts/auth';

import base from '../assets/images/blank-square.png';

export default function Comments(props) {

    const { type, id, title } = props;

    const { token } = useContext(AuthContext);

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    const refMention = useRef(null);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState('');
    const [comments, setComments] = useState([]);
    
    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin();
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);
    
    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    const onSearchChange = useCallback(({ value }) => {
        handleInputChange(value);
    }, []);

    async function debouncedSearch(term) {
        try {
            const response = await api.post(`/mention?search=${term}`, {}, config);
            setSuggestions(defaultSuggestionsFilter(term, response.data.data.mentions));
        } catch (err) {
            console.error('Erro ao buscar usuários:', err);
        }
    }

    function handleInputChange(term) {
        setTimeout(() => {
            debouncedSearch(term);
        }, 300);
    }

    async function submitComment(e){
        e.preventDefault();
        setLoading(true);
        const text = editorState.getCurrentContent().getPlainText();
        try{
            if(text==""){
                return;
            }
            await api.post(`/send-comment`, {type, id, text: text.trim()}, config);
            setEditorState(() =>
                EditorState.createEmpty()
            );
            loadPage();
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadPage(){
        setLoading(true);
       try{
           const response = await api.post(`/comments`, {type, id}, config);
           setComments(response.data.data.comments);
           setLoading(false);
       }catch(err){
           setLoading(false);
       }
   }

   useEffect(() => {
       loadPage();
   }, []);
    
    return (
        <>
        {title!==false ?
        <div className="card custom-card">
            <div className="card-header">
                <div className="card-title">
                    Comentários
                </div>
            </div>
            <div className="card-body">
                {comments!='' &&
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card card-comment">
                            {comments.map((item, index) => (
                            <div className="card-body p-0 comment-line">
                                <Link to={`/profile/${item.username}`} className={`d-flex align-items-center ${item.verified ? 'verified' : ''}`}>
                                    <div className="avatar avatar-rounded me-2">
                                        <img src={base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                    </div>
                                    <div className="flex-fill">
                                        <div className="fs-15 fw-semibold">{item.name}</div>
                                        <p className="mb-0 text-muted fs-11">@{item.username} - {item.date}</p>
                                    </div>
                                </Link>
                                <div className="d-flex mt-2">
                                    <p className="mb-0 text-muted comment-text" dangerouslySetInnerHTML={{__html:item.text}} />
                                </div>
                            </div>))}
                        </div>
                    </div>
                </div>}
                <div className="row gy-3">
                    <div className="col-xl-12">
                        <div className="form-control"
                            onClick={() => {
                                refMention?.current.focus();
                            }}
                        >
                            <Editor
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={setEditorState}
                                plugins={plugins}
                                ref={refMention}
                            />
                            <MentionSuggestions
                                open={open}
                                onOpenChange={onOpenChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchChange}
                                onAddMention={() => {
                                // get the mention object selected
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="text-end">
                    <button className="btn btn-primary-light" onClick={submitComment}>Comentar</button>
                </div>
            </div>
        </div>:
        <div className="card custom-card p-0 mb-0">
            <div className="card-body p-0 pt-3">
                {comments!='' &&
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card card-comment">
                            {comments.map((item, index) => (
                            <div className="card-body p-0 comment-line">
                                <Link to={`/profile/${item.username}`} className={`d-flex align-items-center ${item.verified ? 'verified' : ''}`}>
                                    <div className="avatar avatar-rounded me-2">
                                        <img src={base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                    </div>
                                    <div className="flex-fill">
                                        <div className="fs-15 fw-semibold">{item.name}</div>
                                        <p className="mb-0 text-muted fs-11">@{item.username} - {item.date}</p>
                                    </div>
                                </Link>
                                <div className="d-flex mt-2">
                                    <p className="mb-0 text-muted comment-text" dangerouslySetInnerHTML={{__html:item.text}} />
                                </div>
                            </div>))}
                        </div>
                    </div>
                </div>}
                <div className="row gy-3">
                    <div className="col-xl-12">
                        <div className="form-control"
                            onClick={() => {
                                refMention?.current.focus();
                            }}
                        >
                            <Editor
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={setEditorState}
                                plugins={plugins}
                                ref={refMention}
                            />
                            <MentionSuggestions
                                open={open}
                                onOpenChange={onOpenChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchChange}
                                onAddMention={() => {
                                // get the mention object selected
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer p-0 pt-3">
                <div className="text-end">
                    <button className="btn btn-primary-light" onClick={submitComment}>Comentar</button>
                </div>
            </div>
        </div>}
        </>
    );
}