import React from 'react';
import base from '../assets/images/blank-square.png';
export default function ImageText(props) {
    const { type, image, text } = props;
    return (
        <div className="card custom-card border">
            <div className="card-body">
                <div className="d-flex align-items-start">
                    {type==="invert" ?
                    <>
                        <div dangerouslySetInnerHTML={{__html: text}} />
                        <div className="mx-3 imagetext">
                            <img src={base} className="image-cover" style={{backgroundImage: `url(${image})`}} />
                        </div>
                    </>:
                    <>
                        <div className="me-3 imagetext">
                            <img src={base} className="image-cover" style={{backgroundImage: `url(${image})`}} />
                        </div>
                        <div dangerouslySetInnerHTML={{__html: text}} />
                    </>}
                </div>
            </div>
        </div>
    );
}