import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

import Loader from '../../components/Loader';

import AuthContext from '../../contexts/auth';
import logo from '../../assets/images/logo.png';

export default function Login() {

    const { signIn } = useContext(AuthContext);
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [signin_email, setEmail] = useState('');
    const [signin_password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [view_password, setViewPassword] = useState(false);
    
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');

    async function submitLogin(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(signin_email=="" || signin_password==""){
                setLoading(false);
                setError(true);
                setErrorMessage("Preencha os dados da sua conta!");
                return;
            }
            const response = await signIn(
                signin_email,
                signin_password,
                remember
            );

            if (!response.user) {
                setLoading(false);
                setError(true);
                setErrorMessage(response);
                return;
            }

            setLoading(false);
            setError(false);

            if(query.get('redirect')===null || query.get('redirect')==="null"){
                navigate('/');
            }else{
                navigate(query.get('redirect'));
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage("Ocorreu um erro, tente novamente!");

        }
    }

    function checkToogle(){
        remember ? setRemember(false) : setRemember(true);
    }

    function changePassword(){
        view_password ? setViewPassword(false) : setViewPassword(true);
    }

    return (
        <div className="page-wrapper">
            <Loader loading={loading} />
            <div className="container">
                <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                        <div className="my-5 d-flex justify-content-center">
                            <img src={logo} className="desktop-dark" />
                        </div>
                        <form className="content" onSubmit={submitLogin}>
                            <div className="card custom-card">
                                <div className="card-body p-5">
                                    <p className="h5 fw-semibold mb-2 text-center">Acessar sua Conta</p>
                                    <p className="mb-4 text-muted op-7 fw-normal text-center">Bem-vindo a BRUK</p>
                                    <div className="row gy-3">
                                        {error &&
                                        <div className="col-xl-12 mb-2">
                                            <div className="alert alert-dismissible alert-danger">
                                            {error_message}
                                            </div>
                                        </div>}
                                        
                                        <div className="col-xl-12">
                                            <label htmlFor="signin_email" className="form-label text-default">E-mail</label>
                                            <input type="text" className="form-control form-control-lg" id="signin_email" value={signin_email} onChange={e => setEmail(e.target.value)} />
                                        </div>
                                        <div className="col-xl-12 mb-2">
                                            <label htmlFor="signin_password" className="form-label text-default d-block">Senha</label>
                                            <div className="input-group">
                                                <input type={view_password ? 'text' : 'password'} className="form-control form-control-lg" id="signin_password" value={signin_password} onChange={e => setPassword(e.target.value)} />
                                                <button className="btn btn-light" type="button" onClick={changePassword}>
                                                    {view_password ? <RiEyeLine /> : <RiEyeOffLine /> }
                                                </button>
                                            </div>
                                            <div className="mt-2">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="remember" id="remember" onChange={checkToogle} defaultChecked={remember} />
                                                    <label className="form-check-label text-muted fw-normal" htmlFor="remember">Lembrar-me</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 d-grid mt-2">
                                            <button type="submit" className="btn btn-lg btn-primary">ACESSAR</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
