import React from 'react';
export default function Podcast(props) {
    const { type, id } = props;
    let podcast_content = '';
    if(type=="apple"){
        podcast_content = <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" style={{width: '100%', overflow: 'hidden', background:'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src={`https://embed.podcasts.apple.com/${id}`} />;
    }else if(type=="apple_ep"){
        podcast_content = <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="180" style={{width: '100%', overflow: 'hidden', background:'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src={`https://embed.podcasts.apple.com/${id}`} />;
    }else if(type=="spotify"){
        podcast_content = <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="240" style={{width: '100%', overflow: 'hidden', background:'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"  src={`https://open.spotify.com/embed-podcast/show/${id}`} />;
    }else if(type=="spotify_ep"){
        podcast_content = <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="240" style={{width: '100%', overflow: 'hidden', background:'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"  src={`https://open.spotify.com/embed-podcast/episode/${id}`} />;
    }
    return (
        <>
           {podcast_content}
        </>
    );
}